import React from "react";
import HeaderInner from "./HeaderInner";
import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import configureStore from "../../../store/configureStore";
import Footer from "./Footer";
import {
  create_address,
  get_address_details,
  update_address,
} from "./actions/action-address";
import { history } from "../../../reducers";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useEffect } from "react";
import Loader from "../../common/Loader";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  input: {
    marginBottom: 10,
    background: "#fff",
  },
  formControl: {
    width: "100%",
    background: "#fff",
  },
}));

function EditAddress() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  const { details } = store.getState().myProfile;

  const { addressdetails, showGlobalLoader } = useSelector(
    (state) => state.address
  );
  const [error, setError] = React.useState();

  const [state, setState] = React.useState({
    first_name: "",
    last_name: "",
    address1: "",
    address2: "",
    country: "",
    city: "",
    state: "",
    zipcode: "",
    phone: "",
    default: false,
    username: details.data.username,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value });
  };

  const handleCheck = (event) => {
    // setChecked(event.target.checked);
    console.log("[event.target.checked]", event.target.checked);
    if (event.target.checked) {
      console.log("Before set state", state);
      setState({ ...state, default: true });
      console.log("After set state", state);
    } else {
      setState({ ...state, default: false });
    }
    console.log("state", state);
  };

  function validatePhoneNumber(input_str) {
    console.log("input_str", input_str);
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    return re.test(input_str);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validatePhoneNumber(state.phone)) {
      dispatch(update_address(state, activeOuid, state.id));
      history.push(`/${activeOuid}/address`);
    } else {
      setError("Phone number not valid");
    }
  };
  console.log("state", state);

  useEffect(() => {
    let addressId;
    addressId = window.location.pathname.split("/");
    addressId = addressId[3];
    console.log("addressId", addressId);
    const fetchData = async (dispatch) => {
      await dispatch(get_address_details(addressId, activeOuid));
      console.log(store.getState().address.addressdetails);

      setState({
        ...state,
        first_name: store.getState().address.addressdetails.first_name,
        last_name: store.getState().address.addressdetails.last_name,
        address1: store.getState().address.addressdetails.address1,
        id: store.getState().address.addressdetails.id,
        address2: store.getState().address.addressdetails.address2,
        country: store.getState().address.addressdetails.country,
        city: store.getState().address.addressdetails.city,
        state: store.getState().address.addressdetails.state,
        zipcode: store.getState().address.addressdetails.zipcode,
        phone: store.getState().address.addressdetails.phone,
        default: store.getState().address.addressdetails.default,
      });
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Edit Address" />
      {showGlobalLoader ? (
        <Loader />
      ) : (
        <div className="container--narrow AppPage">
          <h5 className="checkout_title">Edit Address</h5>
          <form onSubmit={handleSubmit}>
            {error && <div className="form_error">{error}</div>}
            <TextField
              id="first_name"
              label="First Name "
              defaultValue={addressdetails.first_name}
              fullWidth
              variant="outlined"
              className={classes.input}
              onChange={handleChange}
              required
            ></TextField>
            <TextField
              id="last_name"
              label="Last Name "
              defaultValue={addressdetails.last_name}
              fullWidth
              variant="outlined"
              className={classes.input}
              onChange={handleChange}
              required
            ></TextField>
            <TextField
              id="phone"
              label="Phone Number  "
              inputProps={{
                maxLength: 10,
                inputMode: "tel",
              }}
              defaultValue={addressdetails.phone}
              fullWidth
              variant="outlined"
              className={classes.input}
              onChange={handleChange}
              required
            ></TextField>

            <TextField
              id="address1"
              label="House Number and street Name "
              defaultValue={addressdetails.address1}
              fullWidth
              variant="outlined"
              className={classes.input}
              onChange={handleChange}
              required
            ></TextField>
            <TextField
              id="address2"
              label="Apartment suite unit etc "
              defaultValue={addressdetails.address2}
              fullWidth
              variant="outlined"
              className={classes.input}
              onChange={handleChange}
              required
            ></TextField>
            <TextField
              id="city"
              label="Town / City "
              defaultValue={addressdetails.city}
              fullWidth
              variant="outlined"
              className={classes.input}
              onChange={handleChange}
              required
            ></TextField>
            <TextField
              id="zipcode"
              label="zipcode  "
              defaultValue={addressdetails.zipcode}
              fullWidth
              variant="outlined"
              className={classes.input}
              onChange={handleChange}
              required
            ></TextField>
            <TextField
              id="state"
              label="State "
              defaultValue={addressdetails.state}
              fullWidth
              variant="outlined"
              className={classes.input}
              onChange={handleChange}
              required
            ></TextField>
            <TextField
              id="country"
              label="country "
              defaultValue={addressdetails.country}
              fullWidth
              variant="outlined"
              className={classes.input}
              onChange={handleChange}
              required
            ></TextField>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleCheck}
                    id="default"
                    name="checked"
                    color="primary"
                    defaultChecked={addressdetails.default}
                  />
                }
                label="Default Address"
              />
            </FormGroup>

            <button className="w-100  secondary_btn" type="submit">
              Save
            </button>
          </form>
        </div>
      )}

      <Footer />
    </div>
  );
}

export default EditAddress;
