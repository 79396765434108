export const RESET_STATE = "RESET_STATE";

export const CREATE_PRODUCT_REQUESTED = "CREATE_PRODUCT_REQUESTED";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_ERRORED = "CREATE_PRODUCT_ERRORED";
export const GET_PRODUCTS_REQUESTED = "GET_PRODUCTS_REQUESTED";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_ERRORED = "GET_PRODUCTS_ERRORED";
export const GET_PRODUCT_DETAILS_REQUESTED = "GET_PRODUCT_DETAILS_REQUESTED";
export const GET_PRODUCT_DETAILS_SUCCESS = "GET_PRODUCT_DETAILS_SUCCESS";
export const GET_PRODUCT_DETAILS_ERRORED = "GET_PRODUCT_DETAILS_ERRORED";
export const UPDATE_PRODUCT_REQUESTED = "UPDATE_PRODUCT_REQUESTED";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_ERRORED = "UPDATE_PRODUCT_ERRORED";
export const DELETE_PRODUCT_REQUESTED = "DELETE_PRODUCT_REQUESTED";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_ERRORED = "DELETE_PRODUCT_ERRORED";

export const CREATE_CATEGORY_REQUESTED = "CREATE_CATEGORY_REQUESTED";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_ERRORED = "CREATE_CATEGORY_ERRORED";
export const GET_CATEGORY_REQUESTED = "GET_CATEGORY_REQUESTED";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_ERRORED = "GET_CATEGORY_ERRORED";
export const GET_CATEGORY_DETAILS_REQUESTED = "GET_CATEGORY_DETAILS_REQUESTED";
export const GET_CATEGORY_DETAILS_SUCCESS = "GET_CATEGORY_DETAILS_SUCCESS";
export const GET_CATEGORY_DETAILS_ERRORED = "GET_CATEGORY_DETAILS_ERRORED";
export const UPDATE_CATEGORY_REQUESTED = "UPDATE_CATEGORY_REQUESTED";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_ERRORED = "UPDATE_CATEGORY_ERRORED";
export const DELETE_CATEGORY_REQUESTED = "DELETE_CATEGORY_REQUESTED";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_ERRORED = "DELETE_CATEGORY_ERRORED";

export const CREATE_CART_REQUESTED = "CREATE_CART_REQUESTED";
export const CREATE_CART_SUCCESS = "CREATE_CART_SUCCESS";
export const CREATE_CART_ERRORED = "CREATE_CART_ERRORED";
export const GET_CART_REQUESTED = "GET_CART_REQUESTED";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_ERRORED = "GET_CART_ERRORED";
export const UPDATE_CART_REQUESTED = "UPDATE_CART_REQUESTED";
export const UPDATE_CART_SUCCESS = "UPDATE_CART_SUCCESS";
export const UPDATE_CART_ERRORED = "UPDATE_CART_ERRORED";
export const DELETE_CART_REQUESTED = "DELETE_CART_REQUESTED";
export const DELETE_CART_SUCCESS = "DELETE_CART_SUCCESS";
export const DELETE_CART_ERRORED = "DELETE_CART_ERRORED";

export const CREATE_ORDER_REQUESTED = "CREATE_ORDER_REQUESTED";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_ERRORED = "CREATE_ORDER_ERRORED";
export const GET_ORDER_REQUESTED = "GET_ORDER_REQUESTED";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_ERRORED = "GET_ORDER_ERRORED";
export const GET_ORDER_DETAILS_REQUESTED = "GET_ORDER_DETAILS_REQUESTED";
export const GET_ORDER_DETAILS_SUCCESS = "GET_ORDER_DETAILS_SUCCESS";
export const GET_ORDER_DETAILS_ERRORED = "GET_ORDER_DETAILS_ERRORED";
export const UPDATE_ORDER_REQUESTED = "UPDATE_ORDER_REQUESTED";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_ERRORED = "UPDATE_ORDER_ERRORED";
export const DELETE_ORDER_REQUESTED = "DELETE_ORDER_REQUESTED";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const DELETE_ORDER_ERRORED = "DELETE_ORDER_ERRORED";

export const CREATE_ADDRESS_REQUESTED = "CREATE_ADDRESS_REQUESTED";
export const CREATE_ADDRESS_SUCCESS = "CREATE_ADDRESS_SUCCESS";
export const CREATE_ADDRESS_ERRORED = "CREATE_ADDRESS_ERRORED";
export const GET_ADDRESS_REQUESTED = "GET_ADDRESS_REQUESTED";
export const GET_ADDRESS_SUCCESS = "GET_ADDRESS_SUCCESS";
export const GET_ADDRESS_ERRORED = "GET_ADDRESS_ERRORED";
export const UPDATE_ADDRESS_REQUESTED = "UPDATE_ADDRESS_REQUESTED";
export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS";
export const UPDATE_ADDRESS_ERRORED = "UPDATE_ADDRESS_ERRORED";
export const DELETE_ADDRESS_REQUESTED = "DELETE_ADDRESS_REQUESTED";
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS";
export const DELETE_ADDRESS_ERRORED = "DELETE_ADDRESS_ERRORED";
export const GET_ADDRESS_DETAILS_REQUESTED = "GET_ADDRESS_DETAILS_REQUESTED";
export const GET_ADDRESS_DETAILS_SUCCESS = "GET_ADDRESS_DETAILS_SUCCESS";
export const GET_ADDRESS_DETAILS_ERRORED = "GET_ADDRESS_DETAILS_ERRORED";

export const CREATE_DOCUMENT_REQUESTED = "CREATE_DOCUMENT_REQUESTED";
export const CREATE_DOCUMENT_SUCCESS = "CREATE_DOCUMENT_SUCCESS";
export const CREATE_DOCUMENT_ERRORED = "CREATE_DOCUMENT_ERRORED";
export const DOCUMENT_UPLOAD_REQUESTED = "DOCUMENT_UPLOAD_REQUESTED";
export const DOCUMENT_UPLOAD_SUCCESS = "DOCUMENT_UPLOAD_SUCCESS";
export const DOCUMENT_UPLOAD_ERRORED = "DOCUMENT_UPLOAD_ERRORED";
export const DELETE_DOCUMENT_REQUESTED = "DELETE_DOCUMENT_REQUESTED";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_ERRORED = "DELETE_DOCUMENT_ERRORED";
