import {
    CREATE_ORDER_REQUESTED,
    CREATE_ORDER_SUCCESS,
    CREATE_ORDER_ERRORED,
    GET_ORDER_REQUESTED,
    GET_ORDER_SUCCESS,
    GET_ORDER_ERRORED,
    GET_ORDER_DETAILS_REQUESTED,
    GET_ORDER_DETAILS_SUCCESS,
    GET_ORDER_DETAILS_ERRORED,
    UPDATE_ORDER_REQUESTED,
    UPDATE_ORDER_SUCCESS,
    UPDATE_ORDER_ERRORED,
    DELETE_ORDER_REQUESTED,
    DELETE_ORDER_SUCCESS,
    DELETE_ORDER_ERRORED,
  } from "../constant/action-types";
  
  const defaultState = {
    showGlobalLoader: false,
    order: [],
    orderdetails:{},
    error: {},
  };
  
  export function order(state = defaultState, { type, payload }) {
    switch (type) {
        case CREATE_ORDER_REQUESTED:
        return {
            ...state,
            showGlobalLoader: true,
        };

        case CREATE_ORDER_SUCCESS:
        return {
            ...state,
            showGlobalLoader: false,
            orderdetails: payload
        };

        case CREATE_ORDER_ERRORED:
        return {
            ...state,
            showGlobalLoader: false,
            error: payload,
        };

        case GET_ORDER_REQUESTED:
        return {
            ...state,
            showGlobalLoader: true,
        };

        case GET_ORDER_SUCCESS:
        return {
            ...state,
            showGlobalLoader: false,
            order: payload,
        };

        case GET_ORDER_ERRORED:
        return {
            ...state,
            showGlobalLoader: false,
            error: payload,
        };
        case GET_ORDER_DETAILS_REQUESTED:
        return {
            ...state,
            showGlobalLoader: true,
        };

        case GET_ORDER_DETAILS_SUCCESS:
        return {
            ...state,
            showGlobalLoader: false,
            orderdetails: payload,
        };

        case GET_ORDER_DETAILS_ERRORED:
        return {
            ...state,
            showGlobalLoader: false,
            error: payload,
        };
        case UPDATE_ORDER_REQUESTED:
        return {
            ...state,
            showGlobalLoader: true,
        };
        
        case UPDATE_ORDER_SUCCESS:
        return {
            ...state,
            showGlobalLoader: false,
            order: payload,
        };
    
        case UPDATE_ORDER_ERRORED:
        return {
            ...state,
            showGlobalLoader: false,
            error: payload,
        };
        case DELETE_ORDER_REQUESTED:
        return {
            ...state,
            showGlobalLoader: true,
        };
        
        case DELETE_ORDER_SUCCESS:
        return {
            ...state,
            showGlobalLoader: false,
            order: payload,
        };
    
        case DELETE_ORDER_ERRORED:
        return {
            ...state,
            showGlobalLoader: false,
            error: payload,
        };
        default:
            return state;
    }
  }
  