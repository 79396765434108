import React, { useEffect } from "react";
import { Button, TextField } from "@material-ui/core";
import HeaderInner from "./HeaderInner";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import configureStore from "../../../store/configureStore";
import DocumentUploader, { delete_file } from "./common/ImageUploader";
import {
  get_category,
  get_category_details,
  update_category,
} from "./actions/action-product";
import Loader from "../../common/Loader";
import { history } from "../../../reducers";
import CloseIcon from "@material-ui/icons/Close";
import Footer from "./Footer";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  input: {
    marginBottom: 10,
    background: "#fff",
  },
}));

export default function EditCategory() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeOuid, activeOuidChain } = store.getState().permission;

  const { categorydetails, showGlobalLoader } = useSelector(
    (state) => state.products
  );

  const [state, setState] = React.useState({
    name: "",
    image: "",
    ouid: "",
    has_gender_affinity: true,
    id: "",
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value });
    console.log("state", state);
  };

  const handleUploadChange = async () => {
    console.log("handleUploadChange");
    const newImageUrl = store.getState().document.documentUrl;
    setState((prevState) => ({ ...prevState, image: newImageUrl }));
  };

  const handleDelete = (event) => {
    event.preventDefault();
    console.log("Handle delete");
    var dUrl = new URL(state.image);
    var dKey = dUrl.pathname;
    dKey = dKey.slice(1);
    console.log("dKey", dKey);

    dispatch(delete_file(dKey, activeOuid));
    setState({
      ...state,
      image: "",
    });
  };

  const handleSubmit = (e, categoryId) => {
    e.preventDefault();
    console.log("Handle submit");
    dispatch(update_category(state, activeOuid, state.id));
    history.push(`/${activeOuid}/category`);
  };

  useEffect(() => {
    let categoryId;
    categoryId = window.location.pathname.split("/");
    categoryId = categoryId[3];
    console.log("categoryId", categoryId);
    const fetchData = async (dispatch) => {
      await dispatch(get_category_details(categoryId));
      const { name, image, ouid, id } = store.getState().products.categorydetails;
      setState({
        ...state,
        name,
        image,
        ouid,
        id,
      });
    };
    fetchData(dispatch);
  }, [dispatch]);

  useEffect(() => {
    console.log("Image state updated:", state.image);
  }, [state.image]);


  return (
    <div className="edit_category_wrap">
      <HeaderInner pageTitle="Edit Category" />
      {showGlobalLoader ? (
        <Loader />
      ) : (
        <div className="container--narrow AppPage">
           <form onSubmit={handleSubmit}>
            <div className="image_input_wrap">
              {state.image && (
                <>
                  <img
                    className="file_input_img_preview"
                    src={state.image}
                    alt="Preview"
                  />
                  <button className="img_delete_btn" onClick={handleDelete}>
                    <CloseIcon />
                  </button>
                </>
              )}
              {state.image === "" && (
                <DocumentUploader
                  activeOuid={activeOuid}
                  activeOuidChain={activeOuidChain}
                  defaultState={state.upload}
                  handleUploadChange={handleUploadChange}
                />
              )}
            </div>
            <TextField
              value={state.name}
              id="name"
              label="Category Name"
              fullWidth
              variant="outlined"
              className={classes.input}
              onChange={handleChange}
              required
            />

            <div className="AppFloatingButtonWrapper t-c">
              <button className="w-100  primary_btn" type="submit">
                Update Category
              </button>
            </div>
            {/* <Button
              className="primary_btn"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => handleSubmit(categorydetails.id)}
            >
              Update Category
            </Button> */}
          </form>
        </div>
      )}
      <Footer />
    </div>
  );
}
