import React from "react";
import { Button, TextField } from "@material-ui/core";
import HeaderInner from "./HeaderInner";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import configureStore from "../../../store/configureStore";
import { create_category } from "./actions/action-product";
import DocumentUploader, { delete_file } from "./common/ImageUploader";
import { history } from "../../../reducers";
import Footer from "./Footer";
import CloseIcon from "@material-ui/icons/Close";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  input: {
    marginBottom: 10,
    background: "#fff",
  },
}));

export default function AddCatergory() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeOuid, activeOuidChain } = store.getState().permission;
  const { documentUrl, showGlobalLoader } = store.getState().document;

  const [state, setState] = React.useState({
    name: "",
    image: "",
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value });
    console.log("state", state);
  };

  const handleDelete = (event) => {
    event.preventDefault();
    console.log("Handle delete");
    var dUrl = new URL(state.image);
    var dKey = dUrl.pathname;
    dKey = dKey.slice(1);
    console.log("dKey", dKey);

    dispatch(delete_file(dKey, activeOuid));
    setState({
      ...state,
      image: "",
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Handle submit");
    dispatch(create_category(state, activeOuid));
    history.push(`/${activeOuid}/category`);
  };

  const handleUploadChange = async () => {
    console.log("handleUploadChange");
    setState({ ...state, image: store.getState().document.documentUrl });
  };
  console.log("documentUrl", documentUrl);

  return (
    <div className="add_product_wrap">
      <HeaderInner pageTitle="Add Category" />
      <div className="container--narrow AppPage">
        <form onSubmit={handleSubmit}>
          <div className="image_input_wrap">
            {state.image && (
              <>
                <img className="file_input_img_preview" src={documentUrl} />
                <button className="img_delete_btn" onClick={handleDelete}>
                  <CloseIcon />
                </button>
              </>
            )}
            {state.image === "" && (
              <DocumentUploader
                activeOuid={activeOuid}
                activeOuidChain={activeOuidChain}
                defaultState={state.upload}
                handleUploadChange={handleUploadChange}
              />
            )}
          </div>
          <TextField
            id="name"
            label="Category Name"
            fullWidth
            variant="outlined"
            className={classes.input}
            onChange={handleChange}
            required
            // error={state.name === ""}
            // helperText={state.name === "" ? "Empty!" : " "}
          />

          <button className="w-100  secondary_btn" type="submit">
            ADD CATEGORY
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
}
