import React from "react";
import { Link } from "react-router-dom";
import configureStore from "../../../../store/configureStore";
import Moment from "react-moment";

const store = configureStore();

export default function OrderCard(props) {
  const { activeOuid } = store.getState().permission;
  console.log("props", props);
  return (
    <div className="order_list_item_wrapper">
      <Link
        className="product_list_item_wrap"
        to={`/${activeOuid}/order-detail/${props.item.id}`}
      >
        <ul className="order_list_wrap">
          <li className="order_list_item">
            <div className="order_list_item_label">Order: </div>
            <div className="order_list_item_value">{props.item.id}</div>
          </li>
          <li className="order_list_item">
            <div className="order_list_item_label">Date: </div>
            <div className="order_list_item_value">
              <Moment format="DD-MM-YYYY">{props.item.createdAt}</Moment>
            </div>
          </li>
          <li className="order_list_item">
            <div className="order_list_item_label">Total: </div>
            <div className="order_list_item_value">
              ₹{props.item.total}/-(1 Item)
            </div>
          </li>
          <li className="order_list_item">
            <div className="order_list_item_label">Status: </div>
            <div className="order_list_item_value">
              {props.item.delivery_status}
            </div>
          </li>
        </ul>
      </Link>
    </div>
  );
}
