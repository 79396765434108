import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderInner from "./HeaderInner";
import Loader from "../../common/Loader";
import configureStore from "../../../store/configureStore";
import { get_order } from "./actions/action-order";
import OrderCard from "./common/OrderCard";
import Footer from "./Footer";

const store = configureStore();

export default function Orders() {
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  const { details } = store.getState().myProfile;
  const { order, showGlobalLoader } = useSelector((state) => state.order);

  function renderOrders(orderitems) {
    console.log("order", orderitems);
    if(orderitems){
      return orderitems.map((item) => (
        <OrderCard key={item.id} item={item} />
        //   <div key={item.product_id}>item</div>
      ));
    } else{
      <div>No orders</div>
    }
    
  }
  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_order(activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Orders" />
      <div className="container--narrow AppPage">
        {showGlobalLoader ? <Loader /> : renderOrders(order)}
      </div>
      <Footer />
    </div>
  );
}
