import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../common/Loader";
import OrderCard from "./common/OrderCard";
import Footer from "./Footer";
import HeaderInner from "./HeaderInner";
import { history } from "../../../reducers";
import configureStore from "../../../store/configureStore";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CommonSnackBar from "../../common/CommonSnackBar";

const store = configureStore();

export default function ThankYou() {
  const { activeOuid } = store.getState().permission;

  const { orderdetails, showGlobalLoader } = useSelector(
    (state) => state.order
  );

  function renderOrder(orderitems) {
    console.log("order", orderitems);
    return orderitems.map((item) => <OrderCard key={item.id} item={item} />);
  }

  return (
    <div>
      <HeaderInner />
      {showGlobalLoader ? (
        <Loader />
      ) : (
        <div className="container--narrow AppPage">
          <div className="thank_you_wrap">
            <CheckCircleIcon />
            <h2 className="thank_you_title">Thank you for Ordering</h2>
            {/* {renderOrder(orderdetails)} */}
            <button className="secondary_btn" onClick={() => history.push(`/`)}>
              Go Home
            </button>
          </div>
        </div>
      )}
      <CommonSnackBar />
      <Footer />
    </div>
  );
}
