import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderInner from "./HeaderInner";
import SearchField from "../../common/SearchField";
import ProductCard from "./common/ProductCard";
import { get_products, search_products } from "./actions/action-product";
import configureStore from "../../../store/configureStore";
import Loader from "../../common/Loader";
import Footer from "./Footer";
import OpenSearch from "./common/OpenSearch";
import MyProductCard from "./common/MyProductCard";
import { Button } from "@material-ui/core";
import { history } from "../../../reducers";
import CommonSnackBar from "../../common/CommonSnackBar";

const store = configureStore();

export default function Shop() {
  const { activeOuid } = store.getState().permission;
  const dispatch = useDispatch();
  const { products, showGlobalLoader } = useSelector((state) => state.products);

  function renderProducts() {
    if (products !== null) {
      return products.map((item) => (
        <MyProductCard
          key={item.id}
          item={item}
          productname={item.name}
          editable={true}
        />
      ));
    } else {
      return <>No product</>;
    }
  }

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      // await dispatch(get_products("/category/apparel", activeOuid));
      await dispatch(get_products(`?seller=${activeOuid}`, activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div className="myShop">
      <HeaderInner pageTitle="My Shop" />
      <div className="container--narrow AppPage">
        <div className="searchWrapper">
          <OpenSearch
            onChange={(e) =>
              dispatch(search_products(e.target.value, activeOuid))
            }
          />
        </div>
        <div>{showGlobalLoader ? <Loader /> : renderProducts()}</div>
      </div>
      <div className="AppFloatingButtonWrapper">
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => history.push(`/${activeOuid}/add-product`)}
        >
          Add Product
        </Button>
      </div>
      <CommonSnackBar />
      <Footer />
    </div>
  );
}
