import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import configureStore from "../../../store/configureStore";
import home from "./images/home.svg";
import notification from "./images/notification.svg";
import cartimg from "./images/cart.svg";
import menu from "./images/menu.svg";
import { ReactReduxContext } from "react-redux";
import appconfig from "../../../config";
import hrm from "./images/hrm.svg";
import crm from "./images/crm.svg";
import dpr from "./images/dpr.svg";
import ecom from "./images/ecom.svg";
import vc from "./images/vc.svg";
import accounts from "./images/accounts.svg";
import { Drawer } from "@material-ui/core";
import apps from "./images/apps.svg";
import ibc from "./images/ibcLogoNew.svg";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "fixed",
    justifyContent: "space-between",
    alignItems: "center",
    bottom: 0,
    left: 0,
    right: 0,
    background: "#fff",
    borderRadius: "70px 10px 0 70px",
    boxShadow: "0px -4px 6px rgba(0, 0, 0, 0.02)",
    padding: "6px 20px 6px 8px",
    zIndex: "8",
  },
  Icon: {
    position: "relative",
  },
  badge: {
    position: "absolute",
    color: "#fff",
    background: "#fc4f4f",
    height: 15,
    width: 15,
    top: -8,
    right: -5,
    borderRadius: 10,
    textAlign: "center",
  },
}));

export default function Footer() {
  const classes = useStyles();
  const theme = useTheme();
  const { activeOuid } = store.getState().permission;
  // const { cart } = store.getState().cart;
  const { cart } = useSelector((state) => state.cart);
  const [isDrawerOpen, setIsDrawerOPen] = useState(false);
  const { jwtToken } = store.getState().jwt.api.credentials.idToken;
  console.log("appconfig", appconfig);
  var hrmAppUrl = appconfig.appui.hrmUrl + "?token=" + jwtToken;
  var ecomAppUrl = appconfig.appui.ecomUrl + "?token=" + jwtToken;
  var crmAppUrl = appconfig.appui.crmUrl + "?token=" + jwtToken;
  var vcAppUrl = appconfig.appui.vcUrl + "?token=" + jwtToken;
  var accountsUrl = appconfig.appui.accountsUrl + "?token=" + jwtToken;
  var baseUrl = appconfig.appui.baseUrl + "/" + activeOuid + "/dashboard";
  console.log("cart.item", cart.items);

  return (
    <div className={classes.root}>
      <div>
        <a href={baseUrl}>
          <img src={ibc} />
        </a>
      </div>
      <div>
        <Link to={`/`}>
          <img src={home} />
        </Link>
      </div>

      <div>
        <div className={classes.Icon} onClick={() => setIsDrawerOPen(true)}>
          <img src={apps} />
        </div>
        <Drawer
          className="edit_option_btn_drawer"
          anchor="bottom"
          open={isDrawerOpen}
          onClose={() => setIsDrawerOPen(false)}
        >
          <div className="edit_option_head">Apps</div>
          <ul className="edit_option_tools_wrap">
            <li
              className="edit_option_tools_item"
              onClick={() => setIsDrawerOPen(false)}
            >
              <a href={crmAppUrl}>
                <img src={crm} />
              </a>
              <p className="AppLabel">CRM</p>
            </li>
            <li
              className="edit_option_tools_item"
              onClick={() => setIsDrawerOPen(false)}
            >
              <a href={hrmAppUrl}>
                <img src={hrm} />
              </a>
              <p className="AppLabel">HRM</p>
            </li>
            <li
              className="edit_option_tools_item"
              onClick={() => setIsDrawerOPen(false)}
            >
              <a href={ecomAppUrl}>
                <img src={ecom} />
              </a>
              <p className="AppLabel">Ecom</p>
            </li>
            <li
              className="edit_option_tools_item"
              onClick={() => setIsDrawerOPen(false)}
            >
              <a href={vcAppUrl}>
                <img src={vc} />
              </a>
              <p className="AppLabel">IBC Connect</p>
            </li>
            <li
              className="edit_option_tools_item"
              onClick={() => setIsDrawerOPen(false)}
            >
              <a href={hrmAppUrl}>
                <img src={dpr} />
              </a>
              <p className="AppLabel">DPR</p>
            </li>
            <li
              className="edit_option_tools_item"
              onClick={() => setIsDrawerOPen(false)}
            >
              <a href={accountsUrl}>
                <img src={accounts} />
              </a>
              <p className="AppLabel">Accounts</p>
            </li>
          </ul>
        </Drawer>
      </div>
      <div>
        <Link to={`/cart`} className={classes.Icon}>
          <img src={cartimg} />
          {cart && (
            <>
              {cart.items !== null && cart.items !== undefined && (
                <span className={classes.badge}>{cart.items.length}</span>
              )}
            </>
          )}
        </Link>
      </div>
      <div>
        <Link to={`/${activeOuid}/e-profile`}>
          <img src={menu} />
        </Link>
      </div>
    </div>
  );
}
