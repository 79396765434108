import React, { useState } from "react";
import prodimg from "../images/prof.png";
import { history } from "../../../../reducers";
import configureStore from "../../../../store/configureStore";
import { useDispatch } from "react-redux";
import { delete_category } from "../actions/action-product";
import CommonConfirm from "../../../common/CommonConfirm";


const store = configureStore();

export default function CategoryCard(props) {
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  const [confirmBox, setConfirmBox] = useState(false);

  const handleDelete = () => {
    setConfirmBox(true); 
  };

  const closeConfirmBox = (confirm) => {
    if (confirm) {
      console.log("props.item.username delete", props.item.id);
      dispatch(delete_category(activeOuid, props.item.id));
      history.push(`/${activeOuid}/category`);
    }
    setConfirmBox(false); 
  };
  return (
    <div>
      <div className="product_list_item_wrap">
        <div className="product_list_item_image">
          <img src={props.item.image} />
        </div>
        <div className="category_list_item_details_wrap">
          <h6 className="product_list_item_name">{props.item.name}</h6>
          {/* <p className="product_list_item_desc">{props.item.description}</p> */}
          {props.editable && (
            <>
              <div className="my_product_card_btn_wrap">
                <button
                  className="edit_btn"
                  onClick={() =>
                    history.push(
                      `/${activeOuid}/edit-category/${props.item.id}`
                    )
                  }
                >
                  Edit
                </button>
                <button onClick={handleDelete} className="delete_btn">
                  Delete
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <CommonConfirm
        confirmBox={confirmBox}
        closeConfirmBox={closeConfirmBox}
      />
    </div>
  );
}
