import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderInner from "./HeaderInner";
import SearchField from "../../common/SearchField";
import { get_category } from "./actions/action-product";
import configureStore from "../../../store/configureStore";
import Loader from "../../common/Loader";
import Footer from "./Footer";
import CategoryCard from "./common/CategoryCard";
import { Button } from "@material-ui/core";
import { history } from "../../../reducers";
import CommonSnackBar from "../../common/CommonSnackBar";

const store = configureStore();

export default function Categories() {
  const { activeOuid } = store.getState().permission;
  const dispatch = useDispatch();
  const { categories, showGlobalLoader } = useSelector(
    (state) => state.products
  );

  function renderCategories(categories) {
    return categories
      .filter((item) => item.ouid === activeOuid)
      .map((item) => (
        <CategoryCard
          key={item.id}
          item={item}
          productname={item.name}
          editable={true}
        />
      ));
  }

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_category(activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Categories" />
      <div className="container--narrow AppPage">
        {/* <div className="searchWrapper">
          
        </div> */}
        <div>
          {showGlobalLoader ? <Loader /> : renderCategories(categories)}
        </div>
        <div className="AppFloatingButtonWrapper">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => history.push(`/${activeOuid}/add-category`)}
          >
            Add Category
          </Button>
        </div>
      </div>
      <Footer />
      <CommonSnackBar />
    </div>
  );
}
