import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get_product_details } from "./actions/action-product";
import {
  create_cart,
  get_cart,
  update_cart,
  update_cart_local,
} from "./actions/action-cart";
import { Link } from "react-router-dom";
import HeaderInner from "./HeaderInner";
import proddetimg from "./images/det.png";
import SmsIcon from "@material-ui/icons/Sms";
import CallIcon from "@material-ui/icons/Call";
import configureStore from "../../../store/configureStore";
import Loader from "../../common/Loader";
import Footer from "./Footer";
import CommonSnackBar from "../../common/CommonSnackBar";

const store = configureStore();

export default function ProductInner(props) {
  const dispatch = useDispatch();
  const { activeOuid, userRoles } = useSelector(state => state.permission);
  const { cart } = useSelector(state => state.cart);

  const [inCart, setInCart] = useState(false);

  let productId;
  const urlpath = window.location.pathname;
  var prodid = urlpath.split("/");
  productId = prodid[2];
  const { productdetails, showGlobalLoader } = useSelector(
    (state) => state.products
  );
  const { details } = useSelector(state => state.myProfile);
  const { userSession } = useSelector(state => state.jwt.api);

  const [state, setState] = React.useState(cart);
  const addToCart = async () => {
    var data = cart;
    if (userSession) {
      if (data.id === "") {
        console.log("No cart", data);
        data = {
          username: details.data.username,
          items: [
            {
              product_id: productdetails.id,
              product_name: productdetails.name,
              quantity: 1,
              price: productdetails.price,
              image: productdetails.image,
            },
          ],
        };
        dispatch(create_cart(data, activeOuid));
        setInCart(true);
      } else {
        console.log("Yes cart", data);
        if (data.items === null) {
          data.items = [];
        }
        var updatCartData = {
          username: details.data.username,
          items: data.items,
        };

        var isNewItemForCart = true;
        updatCartData.items.forEach((element) => {
          if (element.product_id === productdetails.id) {
            console.log(
              "item already exists, so increasing the quantity",
              element.product_id
            );

            element.quantity = element.quantity + 1;
            console.log("updated quantity : ", element.quantity);
            isNewItemForCart = false;
            dispatch(update_cart(updatCartData, activeOuid, data.id));
            setInCart(true);
          }
        });
        if (isNewItemForCart) {
          console.log("new item to the cart", productdetails.id);
          updatCartData.items.push({
            image: productdetails.image,
            product_id: productdetails.id,
            product_name: productdetails.name,
            quantity: 1,
            price: productdetails.price,
          });
          dispatch(update_cart(updatCartData, activeOuid, data.id,"New item added to the cart"));
          setInCart(true);
        }
      }
    }
  };

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_product_details(`?productIDs=${productId}`));
      await dispatch(get_cart(activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch, activeOuid, productId]);

  useEffect(() => {
    const productInCart = cart.items?.some(item => item.product_id === productdetails.id);
    setInCart(productInCart);
  }, [cart, productdetails]);

  return (
    <div>
      <HeaderInner pageTitle="Product Detail" />
      {showGlobalLoader ? (
        <Loader />
      ) : (
        <div className="container--narrow AppPage">
          <div className="product_detail_img_wrap">
            <img src={productdetails.image} />
          </div>
          <h5 className="product_detail_name">{productdetails.name}</h5>
          <span className="product_detail_cat">{productdetails.category}</span>
          <p className="product_detail_desc">{productdetails.description}</p>
          <div className="product_detail_price">₹{productdetails.price}/-</div>
          <h6 className="product_detail_sub_heading">Seller Details</h6>
          <p className="product_detail_desc">{productdetails.ouid}</p>
          <div className="">
            <div className="product_detail_btn_wrap">
              {/* <div className="product_detail_contact_wrap">
                <Link to="/">
                  <SmsIcon />
                </Link>
                <Link to="/">
                  <CallIcon />
                </Link>
              </div> */}
              <div className="product_detail_quote_btn_wrap">
              {userRoles.length !== 0 ? (
                  inCart ? (
                    <Link to="/cart" className="secondary_btn">
                      Go to cart
                    </Link>
                  ) : (
                    <button onClick={() => addToCart()} className="secondary_btn">
                      Add to cart
                    </button>
                  )
                ) : (
                  <Link to={`/login?redirect=${urlpath}`} className="w-100 secondary_btn">
                    Login
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
      <CommonSnackBar />
    </div>
  );
}
