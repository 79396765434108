import {
  CREATE_CART_REQUESTED,
  CREATE_CART_SUCCESS,
  CREATE_CART_ERRORED,
  GET_CART_REQUESTED,
  GET_CART_SUCCESS,
  GET_CART_ERRORED,
  UPDATE_CART_REQUESTED,
  UPDATE_CART_SUCCESS,
  UPDATE_CART_ERRORED,
  DELETE_CART_REQUESTED,
  DELETE_CART_SUCCESS,
  DELETE_CART_ERRORED,
} from "../constant/action-types";

import axios from "axios";

import { showSnackbar } from "../../../../actions/action-snackbar";

import configureStore from "../../../../store/configureStore";
import { CardActionArea } from "@material-ui/core";
import { cart } from "../reducers/reducer-cart";

const store = configureStore();

export const create_cart_requested = () => ({
  type: CREATE_CART_REQUESTED,
});

export const create_cart_success = (payload) => ({
  type: CREATE_CART_SUCCESS,
  payload,
});

export const create_cart_errored = (error) => ({
  type: CREATE_CART_ERRORED,
  error,
});

export const get_cart_requested = () => ({
  type: GET_CART_REQUESTED,
});

export const get_cart_success = (payload) => ({
  type: GET_CART_SUCCESS,
  payload,
});

export const get_cart_errored = (error) => ({
  type: GET_CART_ERRORED,
  error,
});
export const update_cart_requested = () => ({
  type: UPDATE_CART_REQUESTED,
});

export const update_cart_success = (payload) => ({
  type: UPDATE_CART_SUCCESS,
  payload,
});

export const update_cart_errored = (error) => ({
  type: UPDATE_CART_ERRORED,
  error,
});
export const delete_cart_requested = () => ({
  type: DELETE_CART_REQUESTED,
});

export const delete_cart_success = (payload) => ({
  type: DELETE_CART_SUCCESS,
  payload,
});

export const delete_cart_errored = (error) => ({
  type: DELETE_CART_ERRORED,
  error,
});

export const create_cart = (payload, activeOuid) => async (dispatch) => {
  dispatch(create_cart_requested());
  console.log(`Create cart`, payload);
  const { ecomApiEndpoint, credentials } = store.getState().jwt.api;
  if (ecomApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${ecomApiEndpoint}/carts`;
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        console.log("cart created Successfully");
        dispatch(showSnackbar("cart created Successfully", "success"));
        dispatch(create_cart_success(response));
      })
      .catch((err) => {
        console.log("cart creation Failed");
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar("cart creation Failed", "error"));
        dispatch(create_cart_errored(err));
      });
  }
  return null;
};

export const get_cart = (userRolesOuid) => async (dispatch) => {
  console.log("activeOuid", userRolesOuid);
  dispatch(get_cart_requested());
  const { ecomApiEndpoint, credentials } = store.getState().jwt.api;
  if (ecomApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${ecomApiEndpoint}/carts`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": userRolesOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get cart Success", response.data);
        dispatch(get_cart_success(response.data));
      })
      .catch((err) => {
        console.log("Get cart Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get cart Failed", "error"));
        }
        dispatch(get_cart_errored(err));
      });
  }
  return null;
};

export const update_cart =
  (payload, activeOuid, cartId,successMessage = "Cart updated") => async (dispatch) => {
    dispatch(update_cart_requested());
    console.log(`update_cart`);
    console.log(`cartId`, cartId);

    // var payloadData = { data: payload };

    const { ecomApiEndpoint, credentials } = store.getState().jwt.api;
    if (ecomApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${ecomApiEndpoint}/carts/id/${cartId}`;
      const { activeOuid, activeOuidChain } = store.getState().permission;
      const method = "put";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("cart update Success");
          dispatch(showSnackbar(successMessage, "success"));
          dispatch(update_cart_success(response.data));
        })
        .catch((err) => {
          console.log("Cart update Failed");
          dispatch(showSnackbar("cart update Failed", "error"));
          dispatch(update_cart_errored(err));
        });
    }
    return null;
  };
export const update_cart_local = (payload) => async (dispatch) => {
  console.log("update_cart_local_payload", payload);
  dispatch(update_cart_success(payload));
  return null;
};

export const delete_cart = (activeOuid, cartId) => async (dispatch) => {
  dispatch(delete_cart_requested());
  console.log(`delete_cart`);
  const { ecomApiEndpoint, credentials } = store.getState().jwt.api;
  if (ecomApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${ecomApiEndpoint}/carts/id/${cartId}`;
    const method = "delete";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Delete product Success");
        // dispatch(showSnackbar("cart deleted successfully", "success"));
        dispatch(delete_cart_success());
        dispatch(get_cart(activeOuid));
      })
      .catch((err) => {
        if (err.response) {
          console.log(
            "Delete cart Failed :",
            JSON.stringify(err.response.data)
          );
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Delete cart failed", "error"));
        }
        dispatch(delete_cart_errored(err));
      });
  }
  return null;
};
