import React, { useState } from "react";
import BorderColorIcon from "@material-ui/icons/BorderColor";

function CheckoutAddressCard(props) {
  function handleScroll(id) {
    document.getElementById(id).scrollIntoView({ inline: "center" });
  }

  return (
    <div
      // className="address_card_wrap"
      id={props.item.id}
      className={`address_card_wrap ${
        props.active === props.index ? "active" : "inactive"
      }`}
      onClick={() => {
        props.selectAddress(props.item);
        props.setActive(props.index);
        handleScroll(props.item.id);
      }}
    >
      <div className="address_card">
        <div className="address_card_left">
          <div className="address_card_top">
            <h5 className="product_list_item_name">
              {props.item.first_name} {props.item.last_name}
            </h5>
            {props.editable && (
              <button className="edit_icon_btn">
                <BorderColorIcon />
              </button>
            )}
          </div>

          <div className="address_card_detail">
            {props.item.address1} {props.item.address2}
          </div>

          <div className="address_card_detail">
            {props.item.country},{props.item.city}, {props.item.state}
          </div>
          <div className="address_card_detail">{props.item.zipcode}</div>
          {props.item.default === true && (
            <div className="address_card_default">Default Address</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CheckoutAddressCard;
