import {
  CREATE_PRODUCT_REQUESTED,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_ERRORED,
  GET_PRODUCTS_REQUESTED,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERRORED,
  GET_PRODUCT_DETAILS_REQUESTED,
  GET_PRODUCT_DETAILS_SUCCESS,
  GET_PRODUCT_DETAILS_ERRORED,
  UPDATE_PRODUCT_REQUESTED,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_ERRORED,
  DELETE_PRODUCT_REQUESTED,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_ERRORED,
  CREATE_CATEGORY_REQUESTED,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_ERRORED,
  UPDATE_CATEGORY_REQUESTED,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_ERRORED,
  GET_CATEGORY_REQUESTED,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_ERRORED,
  GET_CATEGORY_DETAILS_REQUESTED,
  GET_CATEGORY_DETAILS_SUCCESS,
  GET_CATEGORY_DETAILS_ERRORED,
  DELETE_CATEGORY_REQUESTED,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_ERRORED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: false,
  products: [],
  categories: [],
  error: {},
  productdetails: {},
  categorydetails: {},
};

export function products(state = defaultState, { type, payload }) {
  switch (type) {
    case CREATE_PRODUCT_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_PRODUCT_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_PRODUCTS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        products: payload,
      };

    case GET_PRODUCTS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case GET_PRODUCT_DETAILS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        productdetails: payload,
      };

    case GET_PRODUCT_DETAILS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case UPDATE_PRODUCT_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        productdetails: payload,
      };

    case UPDATE_PRODUCT_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case DELETE_PRODUCT_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case DELETE_PRODUCT_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case CREATE_CATEGORY_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_CATEGORY_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_CATEGORY_DETAILS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_CATEGORY_DETAILS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        categorydetails: payload,
      };

    case GET_CATEGORY_DETAILS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case UPDATE_CATEGORY_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        products: payload,
      };

    case UPDATE_CATEGORY_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_CATEGORY_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        categories: payload,
      };

    case GET_CATEGORY_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case DELETE_CATEGORY_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case DELETE_CATEGORY_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    default:
      return state;
  }
}
