import { Collapse, Button, TextField, MenuItem, Select, InputLabel, FormControl } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CartItem from "./common/CartItem";
import { useSelector, useDispatch } from "react-redux";
import Footer from "./Footer";
import HeaderInner from "./HeaderInner";
import { makeStyles } from "@material-ui/core/styles";
import configureStore from "../../../store/configureStore";
import { create_address, get_address } from "./actions/action-address";
import { create_order } from "./actions/action-order";
import { history } from "../../../reducers";
import AddressCard from "./common/AddressCard";
import { delete_cart } from "./actions/action-cart";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckoutAddressCard from "./common/CheckoutAddressCard";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  input: {
    marginBottom: 10,
    background: "#fff",
  },
  formControl: {
    width: "100%",
    marginBottom: 10,
  },
}));

// const cart = {
//   id: "1c682dda-9cff-433d-878b-7ed55daa84e8",
//   username: "haripriyas85@gmail.com",
//   items: [
//     {
//       product_id: "1c718a81-920e-4367-b76f-a7f9edfdc122",
//       product_name: "Black Backpack",
//       quantity: 2,
//       price: 122.9,
//     },
//     {
//       product_id: "1b718a81-920e-4367-b76f-a7f9edfdc122",
//       product_name: "Green Backpack",
//       quantity: 1,
//       price: 122.9,
//     },
//   ],
//   ouid: "devouid",
//   entity: "cart",
// };

export default function Checkout() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  const { details } = store.getState().myProfile;
  const { cart, showGlobalLoader } = useSelector((state) => state.cart);
  const { orderdetails } = useSelector((state) => state.order);
  const { address } = useSelector((state) => state.address);

  const [addAddress, setAddAddress] = React.useState(false);
  const [active, setActive] = useState(0);
  const [error, setError] = React.useState();

  const [shippingAddress, setShippingAddress] = React.useState({
    first_name: "",
    last_name: "",
    address1: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
    phone: "",
    default: false,
    username: details.data.username,
  });

  const [state, setState] = React.useState({
    total: "",
    items: [
      // {
      //   product_id: "1c718a81-920e-4367-b76f-a7f9edfdc122",
      //   product_name: "Black Backpack",
      //   price: 32.0,
      //   quantity: 1,
      // },
      // {
      //   product_id: "1b718a81-920e-4367-b76f-a7f9edfdc122",
      //   product_name: "Red Backpack",
      //   price: 32.0,
      //   quantity: 2,
      // },
    ],
    username: details.data.username,
    collection_phone: "",
    delivery_type: "",
    delivery_status: "",
    delivery_complete: false,
    channel: "channel112",
    shipping_address: {
      first_name: "",
      last_name: "",
      address1: "",
      address2: "",
      country: "",
      state: "",
      city: "",
      zipcode: "",
      phone: "",
      default: false,
      username: details.data.username,
    },
    channel_detail: {
      channel_id: 112,
      channel_geo: "whatis this?",
    },
  });


  const indianStates = [
    'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat',
    'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh',
    'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab',
    'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand',
    'West Bengal'
  ];

  const handleAddAddressClick = (event) => {
    setAddAddress(!addAddress);

    setState({
      ...state,
      shipping_address: {
        first_name: "",
        last_name: "",
        address1: "",
        address2: "",
        country: "",
        state: "",
        city: "",
        zipcode: "",
        phone: "",
        default: false,
        username: details.data.username,
      },
    });
    console.log("newAddress", state);
  };

  const selectAddress = (item) => {
    console.log("Addtem", item);

    setState({
      ...state,
      shipping_address: {
        first_name: item.first_name,
        last_name: item.last_name,
        address1: item.address1,
        address1: item.address1,
        country: item.country,
        state: item.state,
        city: item.city,
        zipcode: item.zipcode,
        phone: item.phone,
        default: item.default,
        username: item.username,
      },
    });
    console.log("addednewAddress", state);
  };

  const handleCheck = (event) => {
    // setChecked(event.target.checked);
    console.log("[event.target.checked]", event.target.checked);
    if (event.target.checked) {
      console.log("Before set state", state);
      setState({
        // ...state, shipping_address: { default: true }
        ...state,
        shipping_address: {
          ...state.shipping_address,
          default: true,
        },
      });
      console.log("After set state", state);
    } else {
      setState({
        // ...state, shipping_address: { default: false }
        ...state,
        shipping_address: {
          ...state.shipping_address,
          default: false,
        },
      });
    }
    console.log("state", state);
  };

  function renderAddress(addressitems) {
    console.log("address", addressitems);
    var addressData = addressitems;
    addressData.some(
      (item, idx) =>
        item.default == true &&
        addressData.unshift(
          // remove the found item, in-place (by index with splice),
          // returns an array of a single item removed
          addressData.splice(idx, 1)[0]
        )
    );
    console.log("addressData", addressData);

    return addressitems.map((item, index) => (
      <CheckoutAddressCard
        key={item.id}
        item={item}
        selectAddress={selectAddress}
        active={active}
        setActive={setActive}
        index={index}
      />
    ));
  }

  function renderCheckoutItem(checkoutItems) {
    return checkoutItems.map((item) => (
      <CartItem
        key={item.id}
        item={item}
        productname={item.name}
        cartCount={false}
        deleteOption={false}
      />
    ));
  }

  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.id]: event.target.value });
  // };
  // const handleBillingAddressChange = (event) => {
  //   setState({ ...state, [event.target.id]: event.target.value });
  // };
  const handleShippingAddressChange = (event) => {
    const { id, value } = event.target;
    console.log(`[event.target.id]: ${id}, [event.target.value]: ${value}`);
    setState((prevState) => ({
      ...prevState,
      shipping_address: {
        ...prevState.shipping_address,
        [id]: value,
      },
    }));
  };
  
  console.log("state", state);

  function getSubtotal(cartItems) {
    var subtotal = 0;
    cartItems.map((item) => (subtotal = subtotal + item.price * item.quantity));
    return subtotal;
  }
  let cartId = cart.id;
  console.log("cartId", cartId);

 

  function validatePhoneNumber(input_str) {
    console.log("input_str", input_str);
    var re = /^[\+]?[0-9]{1,3}?[-\s\.]?[0-9]{1,4}?[-\s\.]?[0-9]{1,4}?[-\s\.]?[0-9]{1,9}$/;
    return re.test(input_str);
  }
  

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Create address", state);
    console.log("Phone number to validate:", state.shipping_address.phone);
    if (validatePhoneNumber(state.shipping_address.phone)) {
      dispatch(create_address(state.shipping_address, activeOuid));
      dispatch(create_order(state, activeOuid, cartId));
      history.push(`/${activeOuid}/thank-you`);
    } else {
      setError("Phone number not valid");
    }
  };
  

  const handlePlaceOrder = () => {
    console.log("Order confirmed", state);
    dispatch(create_order(state, activeOuid, cartId));
    history.push(`/${activeOuid}/thank-you`);
  };

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_address(activeOuid));

      const total = getSubtotal(cart.items);
      console.log("cart", cart);
      console.log("store.getState().address", store.getState().address);
      if (store.getState().address.address != null) {
        setState({
          ...state,
          total: getSubtotal(cart.items),
          items: cart.items,
          shipping_address: {
            first_name: store.getState().address.address[0].first_name,
            last_name: store.getState().address.address[0].last_name,
            address1: store.getState().address.address[0].address1,
            address1: store.getState().address.address[0].address1,
            country: store.getState().address.address[0].country,
            state: store.getState().address.address[0].state,
            city: store.getState().address.address[0].city,
            zipcode: store.getState().address.address[0].zipcode,
            phone: store.getState().address.address[0].phone,
            default: store.getState().address.address[0].default,
            username: store.getState().address.address[0].username,
          },
        });
      }
      console.log("shipping state", state);
    };
    fetchData(dispatch);

    console.log("default", state);
  }, [dispatch]);

  console.log("Form", state);
  return (
    <div className="checkout_wrapper">
      <HeaderInner pageTitle="Checkout" />
      <div className="container--narrow AppPage">
        {renderCheckoutItem(cart.items)}
        <ul className="checkout_total_box">
          <li className="checkout_total_item">
            <div className="checkout_total_item_label">SUBTOTAL</div>
            <div className="checkout_total_item_price">
              {getSubtotal(cart.items)}/-
            </div>
          </li>
          <li className="checkout_total_item">
            <div className="checkout_total_item_label">SHIPPING </div>
            <div className="checkout_total_item_price">₹ 90/-</div>
          </li>
          <li className="checkout_total_item">
            <div className="checkout_total_item_label">GRAND TOTAL</div>
            <div className="checkout_total_item_price">
              ₹ {getSubtotal(cart.items) + 90}/-
            </div>
          </li>
        </ul>
        {address && (
          <>
            <div className="address_card_wrapper">
              {address && renderAddress(address)}
            </div>
          </>
        )}
        <div className=" w_box mb ">
          <div className="add_item_wrap">
            <button
              className="add_address_btn d-inline mt mb"
              onClick={() => {
                handleAddAddressClick();
              }}
              aria-expanded={addAddress}
              aria-label="add address"
            >
              + ADD ADDRESS
            </button>
          </div>
        </div>
        <Collapse in={addAddress}>
          <h5 className="checkout_title">SHIPPING</h5>
          <form onSubmit={handleSubmit}>
            {error && <div className="form_error">{error}</div>}
            <TextField
              id="first_name"
              label="First Name "
              fullWidth
              variant="outlined"
              className={classes.input}
              onChange={handleShippingAddressChange}
              required
            ></TextField>
            <TextField
              id="last_name"
              label="Last Name "
              fullWidth
              variant="outlined"
              className={classes.input}
              onChange={handleShippingAddressChange}
              required
            ></TextField>
            <TextField
              id="phone"
              label="Phone Number  "
              inputProps={{
                maxLength: 12,
                minLength: 10,
                inputMode: "tel",
              }}
              fullWidth
              variant="outlined"
              className={classes.input}
              onChange={handleShippingAddressChange}
              required
            ></TextField>

            <TextField
              required
              id="zipcode"
              label="Postal Zip"
              fullWidth
              variant="outlined"
              className={classes.input}
              onChange={handleShippingAddressChange}
              inputProps={{
                  maxLength: 6,
                  minLength: 6,
                  pattern: "\\d{6}",
                }}
            ></TextField>
            <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="state-label">State</InputLabel>
            <Select
              required
              id="state"
              label="State "
              fullWidth
              variant="outlined"
              className={classes.input}
              onChange={handleShippingAddressChange}
            >
              {indianStates.map((stateName) => (
                <MenuItem key={stateName} value={stateName}>
                  {stateName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
            <h5 className="checkout_title">Address</h5>
            <TextField
              required
              id="address1"
              label="House Number and street Name "
              fullWidth
              variant="outlined"
              className={classes.input}
              onChange={handleShippingAddressChange}
            ></TextField>
            <TextField
              required
              id="address2"
              label="Apartment suite unit etc "
              fullWidth
              variant="outlined"
              className={classes.input}
              onChange={handleShippingAddressChange}
            ></TextField>
            <TextField
              required
              id="city"
              label="Town / City "
              fullWidth
              variant="outlined"
              className={classes.input}
              onChange={handleShippingAddressChange}
            ></TextField>
            <TextField
              required
              id="country"
              label="Country"
              fullWidth
              variant="outlined"
              className={classes.input}
              onChange={handleShippingAddressChange}
            ></TextField>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleCheck}
                    id="default"
                    name="checked"
                    color="primary"
                  />
                }
                label="Default Address"
              />
            </FormGroup>

            <>
              <button type="submit" className="w-100 secondary_btn mb">
                Place Order
              </button>
            </>
          </form>
        </Collapse>
        {!addAddress && (
          <>
            {address && address.length !== 0 && (
              <button
                onClick={() => {
                  handlePlaceOrder();
                }}
                className="w-100 secondary_btn"
              >
                Place Order
              </button>
            )}
          </>
        )}
      </div>

      {/* <Footer /> */}
    </div>
  );
}
