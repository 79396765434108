import React, { useState } from "react";
import prodimg from "../images/prof.png";
import configureStore from "../../../../store/configureStore";
import { history } from "../../../../reducers";
import { delete_product } from "../actions/action-product";
import { useDispatch } from "react-redux";
import CommonConfirm from "../../../common/CommonConfirm";

const store = configureStore();

export default function MyProductCard(props) {
  const { activeOuid } = store.getState().permission;
  const dispatch = useDispatch();
  const [confirmBox, setConfirmBox] = useState(false);

  const handleDelete = () => {
    setConfirmBox(true); 
  };

  const closeConfirmBox = (confirm) => {
    if (confirm) {
      console.log("props.item.username delete", props.item.id);
      dispatch(delete_product(activeOuid, props.item.id));
      history.push(`/${activeOuid}/my-shop`);
    }
    setConfirmBox(false); 
  };
  return (
    <div className="product_list_item_wrapper">
      {/* href=`/product-details/${props.item.id}` */}
      <div className="product_list_item_wrap">
        <div className="product_list_item_image">
          <img src={props.item.image} />
        </div>
        <div className="product_list_item_details_wrap">
          <h6 className="product_list_item_name">{props.item.name}</h6>
          <span className="product_list_item_cat">{props.item.category}</span>
          <p className="product_list_item_desc">{props.item.description}</p>
          <div className="product_list_item_price">Rs {props.item.price}/-</div>
          {props.editable && (
            <>
              <div className="my_product_card_btn_wrap">
                <button
                  className="edit_btn"
                  onClick={() =>
                    history.push(`/${activeOuid}/edit-product/${props.item.id}`)
                  }
                >
                  Edit
                </button>
                <button onClick={handleDelete} className="delete_btn">
                  Delete
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <CommonConfirm
        confirmBox={confirmBox}
        closeConfirmBox={closeConfirmBox}
      />
    </div>
  );
}
