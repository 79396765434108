import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import configureStore from "../../../store/configureStore";
import Header from "./Header";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import Home from "./Home";
import ProductCategory from "./ProductCategory";
import AddProduct from "./AddProduct";
import AddCatergory from "./AddCatergory";
import ProductInner from "./ProductInner";
import EditProduct from "./EditProduct";
import EditCategory from "./EditCategory";
import Cart from "./Cart";
import Checkout from "./Checkout";
import Address from "./Address";
import Profile from "./Profile";
import { get_cart } from "./actions/action-cart";
import Shop from "./MyShop";
import Orders from "./Orders";
import Categories from "./Categories";
import OrderDetails from "./OrderDetails";
import AddAddress from "./AddAddress";
import ThankYou from "./ThankYou";
import EditAddress from "./EditAddress";

const store = configureStore();
export default function EcomApp() {
  const dispatch = useDispatch();
  const activeOuid = store.getState().permission;
  // class EcomApp extends Component {
  useEffect(() => {
    // console.log("inuseEffect");
    // const fetchData = async (dispatch) => {
    //   await dispatch(get_cart(activeOuid));
    // };
    // fetchData(dispatch);
  }, [dispatch]);

  const { permissions } = store.getState().permission;
  return (
    <div className="EcomApp">
      <Switch>
        <Route exact path="/" render={(props) => <Home />} />

        <Route
          exact
          path="/:id/add-product"
          render={(props) => <AddProduct />}
        />
        <Route
          exact
          path="/:id/add-category"
          render={(props) => <AddCatergory />}
        />
        <Route
          exact
          path="/product-category/:id"
          render={(props) => <ProductCategory />}
        />
        <Route
          exact
          path="/product-detail/:id"
          render={(props) => <ProductInner />}
        />

        <Route exact path="/:id/e-profile" render={(props) => <Profile />} />
        <Route exact path="/:id/my-shop" render={(props) => <Shop />} />

        <Route
          exact
          path="/:id/edit-product/:id"
          render={(props) => <EditProduct />}
        />

        <Route exact path="/:id/category" render={(props) => <Categories />} />
        <Route
          exact
          path="/:id/edit-category/:id"
          render={(props) => <EditCategory />}
        />
        <Route exact path="/cart" render={(props) => <Cart />} />
        <Route exact path="/:id/checkout" render={(props) => <Checkout />} />
        <Route exact path="/:id/orders" render={(props) => <Orders />} />
        <Route
          exact
          path="/:id/order-detail/:id"
          render={(props) => <OrderDetails />}
        />
        <Route exact path="/:id/address" render={(props) => <Address />} />
        <Route
          exact
          path="/:id/add-address"
          render={(props) => <AddAddress />}
        />
        <Route
          exact
          path="/:id/edit-address/:id"
          render={(props) => <EditAddress />}
        />
        <Route exact path="/:id/thank-you" render={(props) => <ThankYou />} />
      </Switch>
    </div>
  );
}
