import {
  CREATE_ADDRESS_REQUESTED,
  CREATE_ADDRESS_SUCCESS,
  CREATE_ADDRESS_ERRORED,
  GET_ADDRESS_REQUESTED,
  GET_ADDRESS_SUCCESS,
  GET_ADDRESS_ERRORED,
  UPDATE_ADDRESS_REQUESTED,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_ERRORED,
  DELETE_ADDRESS_REQUESTED,
  DELETE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_ERRORED,
  GET_ADDRESS_DETAILS_REQUESTED,
  GET_ADDRESS_DETAILS_SUCCESS,
  GET_ADDRESS_DETAILS_ERRORED,
} from "../constant/action-types";

import axios from "axios";

import { showSnackbar } from "../../../../actions/action-snackbar";

import configureStore from "../../../../store/configureStore";

const store = configureStore();

export const create_address_requested = () => ({
  type: CREATE_ADDRESS_REQUESTED,
});

export const create_address_success = () => ({
  type: CREATE_ADDRESS_SUCCESS,
});

export const create_address_errored = (error) => ({
  type: CREATE_ADDRESS_ERRORED,
  error,
});

export const get_address_requested = () => ({
  type: GET_ADDRESS_REQUESTED,
});

export const get_address_success = (payload) => ({
  type: GET_ADDRESS_SUCCESS,
  payload,
});

export const get_address_errored = (error) => ({
  type: GET_ADDRESS_ERRORED,
  error,
});
export const get_address_details_requested = () => ({
  type: GET_ADDRESS_DETAILS_REQUESTED,
});

export const get_address_details_success = (payload) => ({
  type: GET_ADDRESS_DETAILS_SUCCESS,
  payload,
});

export const get_address_details_errored = (error) => ({
  type: GET_ADDRESS_DETAILS_ERRORED,
  error,
});

export const update_address_requested = () => ({
  type: UPDATE_ADDRESS_REQUESTED,
});

export const update_address_success = (payload) => ({
  type: UPDATE_ADDRESS_SUCCESS,
  payload,
});

export const update_address_errored = (error) => ({
  type: UPDATE_ADDRESS_ERRORED,
  error,
});
export const delete_address_requested = () => ({
  type: DELETE_ADDRESS_REQUESTED,
});

export const delete_address_success = (payload) => ({
  type: DELETE_ADDRESS_SUCCESS,
  payload,
});

export const delete_address_errored = (error) => ({
  type: DELETE_ADDRESS_ERRORED,
  error,
});

export const create_address = (payload, activeOuid) => async (dispatch) => {
  dispatch(create_address_requested());
  console.log(`Create address`);
  const { ecomApiEndpoint, credentials } = store.getState().jwt.api;
  if (ecomApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${ecomApiEndpoint}/addresses`;
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        console.log("address created Successfully");
        dispatch(showSnackbar("address created Successfully", "success"));
        dispatch(create_address_success(response));
        dispatch(get_address(activeOuid));
      })
      .catch((err) => {
        console.log("address creation Failed");
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar("address creation Failed", "error"));
        dispatch(create_address_errored(err));
      });
  }
  return null;
};

export const get_address = (activeOuid) => async (dispatch) => {
  dispatch(get_address_requested());
  const { ecomApiEndpoint, credentials } = store.getState().jwt.api;
  if (ecomApiEndpoint && credentials) {
    console.log(`hrmApiEndpoint`);

    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${ecomApiEndpoint}/addresses`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get address Success", response.data);
        dispatch(get_address_success(response.data.items));
      })
      .catch((err) => {
        console.log("Get address Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get address Failed", "error"));
        }
        dispatch(get_address_errored(err));
      });
  }
  return null;
};

export const get_address_details =
  (addressID, activeOuid) => async (dispatch) => {
    dispatch(get_address_details_requested());
    const { ecomApiEndpoint, credentials } = store.getState().jwt.api;
    if ((ecomApiEndpoint, credentials)) {
      console.log(`ecomApiEndpoint`);

      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${ecomApiEndpoint}/addresses/id/${addressID}`;
      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get address details Success", response.data);
          dispatch(get_address_details_success(response.data));
          return response.data;
        })
        .catch((err) => {
          console.log("Get address details Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get address details Failed", "error"));
          }
          dispatch(get_address_details_errored(err));
        });
    }
    return null;
  };

export const update_address =
  (payload, activeOuid, addressID) => async (dispatch) => {
    dispatch(update_address_requested());
    console.log(`update address`);

    const { ecomApiEndpoint, credentials } = store.getState().jwt.api;
    if (ecomApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${ecomApiEndpoint}/addresses/id/${addressID}`;
      const { activeOuid, activeOuidChain } = store.getState().permission;
      const method = "put";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("address Updation Success");
          dispatch(showSnackbar("address Updated Successfully", "success"));
          dispatch(update_address_success());
          dispatch(get_address(activeOuid));
        })
        .catch((err) => {
          console.log("address updation Failed");
          dispatch(showSnackbar("address updation Failed", "error"));
          dispatch(update_address_errored(err));
        });
    }
    return null;
  };

export const delete_address = (activeOuid, addressid) => async (dispatch) => {
  dispatch(delete_address_requested());
  console.log(`delete_address`);
  const { ecomApiEndpoint, credentials } = store.getState().jwt.api;
  if (ecomApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${ecomApiEndpoint}/addresses/id/${addressid}`;
    const method = "delete";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Delete address Success");
        dispatch(showSnackbar("address deleted successfully", "success"));
        dispatch(delete_address_success(response));
        dispatch(get_address(activeOuid));
      })
      .catch((err) => {
        if (err.response) {
          console.log(
            "Delete address Failed :",
            JSON.stringify(err.response.data)
          );
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Delete failed", "error"));
        }
        dispatch(delete_address_errored(err));
      });
  }
  return null;
};
