import { createBrowserHistory } from "history";
import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import * as jwt from "./reducer-jwt";
import * as auth_state from "./reducer-auth";
import * as profile from "./reducer-myProfile";
import * as snackbar from "./reducer-snackbar";
import * as orgunit from "./reducer-orgunit";
import * as permission from "./reducer-permissions";
import * as userProfile from "./reducer-userProfiles";
import * as orgUserProfile from "./reducer-orguser";
import * as products from "../components/pages/Ecom/reducers/reducer-product";
import * as cart from "../components/pages/Ecom/reducers/reducer-cart";
import * as order from "../components/pages/Ecom/reducers/reducer-order";
import * as address from "../components/pages/Ecom/reducers/reducer-address";
import * as document from "../components/pages/Ecom/reducers/reducer-document";

import * as config from "./reducer-config";
import * as appUserProfile from "./reducer-appuser";

import { RESET_STATE } from "../constant/action-types";

import { cleanState } from "../utils";

export const history = createBrowserHistory();

const router = { router: connectRouter(history) };

const Reducers = Object.assign(
  router,
  jwt,
  auth_state,
  permission,
  config,
  orgunit,
  snackbar,
  profile,
  userProfile,
  orgUserProfile,
  appUserProfile,
  products,
  cart,
  order,
  address,
  document
);

const allReducers = combineReducers(Reducers);

const rootReducer = (state, action) => {
  let resultState = state;
  if (action.type === RESET_STATE) {
    console.log("Reset State");
    resultState = cleanState(resultState, [
      "router",
      "jwt",
      "auth_state",
      "permission",
      "config",
      "orgunit",
      "snackbar",
      "profile",
      "userProfile",
      "orgUserProfile",
      "appUserProfile",
      "products",
      "cart",
      "order",
      "address",
      "document"
    ]);
  }
  return allReducers(resultState, action);
};

export default rootReducer;
