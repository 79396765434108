import React from "react";
import HeaderInner from "./HeaderInner";
import { Button, TextField, MenuItem, Select, InputLabel, FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import configureStore from "../../../store/configureStore";
import Footer from "./Footer";
import { create_address } from "./actions/action-address";
import { history } from "../../../reducers";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  input: {
    marginBottom: 10,
    background: "#fff",
  },
  formControl: {
    width: "100%",
    background: "#fff",
    marginBottom: 10,
  },
}));

const indianStates = [
  'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat',
  'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh',
  'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab',
  'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand',
  'West Bengal'
];

export default function AddAddress() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  const { details } = store.getState().myProfile;
  const [error, setError] = React.useState();

  const [state, setState] = React.useState({
    first_name: "",
    last_name: "",
    address1: "",
    address2: "",
    country: "",
    city: "",
    state: "",
    zipcode: "",
    phone: "",
    default: false,
    username: details.data.username,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value });
  };

  const handleSelectChange = (event) => {
    setState({ ...state, state: event.target.value });
  };

  const handleCheck = (event) => {
    setState({ ...state, default: event.target.checked });
  };

  function validatePhoneNumber(input_str) {
    console.log("input_str", input_str);
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    return re.test(input_str);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validatePhoneNumber(state.phone)) {
      dispatch(create_address(state, activeOuid));
      history.push(`/${activeOuid}/address`);
    } else {
      setError("Phone number not valid");
    }
  };
  console.log("state", state);

  return (
    <div>
      <HeaderInner pageTitle="Add Address" />
      <div className="container--narrow AppPage">
        <h5 className="checkout_title">Address</h5>
        <form onSubmit={handleSubmit}>
          {error && <div className="form_error">{error}</div>}
          <TextField
            id="first_name"
            label="First Name "
            fullWidth
            variant="outlined"
            className={classes.input}
            onChange={handleChange}
            required
          ></TextField>
          <TextField
            id="last_name"
            label="Last Name "
            fullWidth
            variant="outlined"
            className={classes.input}
            onChange={handleChange}
            required
          ></TextField>
          <TextField
            id="phone"
            label="Phone Number  "
            inputProps={{
              maxLength: 12,
              minLength:10,
              inputMode: "tel",
            }}
            fullWidth
            variant="outlined"
            className={classes.input}
            onChange={handleChange}
            required
          ></TextField>

          <TextField
            id="address1"
            label="House Number and street Name "
            fullWidth
            variant="outlined"
            className={classes.input}
            onChange={handleChange}
            required
          ></TextField>
          <TextField
            id="address2"
            label="Apartment suite unit etc "
            fullWidth
            variant="outlined"
            className={classes.input}
            onChange={handleChange}
            required
          ></TextField>
          <TextField
            id="city"
            label="Town / City "
            fullWidth
            variant="outlined"
            className={classes.input}
            onChange={handleChange}
            required
          ></TextField>
          <TextField
            id="zipcode"
            label="zipcode  "
            fullWidth
            variant="outlined"
            className={classes.input}
            onChange={handleChange}
            required
            inputProps={{
              maxLength: 6,
              minLength: 6,
              pattern: "\\d{6}",
            }}
          ></TextField>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="state-label">State</InputLabel>
            <Select
              labelId="state-label"
              id="state"
              value={state.state}
              onChange={handleSelectChange}
              label="State"
              required
              fullWidth
            >
              {indianStates.map((stateName) => (
                <MenuItem key={stateName} value={stateName}>
                  {stateName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            id="country"
            label="country "
            fullWidth
            variant="outlined"
            className={classes.input}
            onChange={handleChange}
            required
          ></TextField>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleCheck}
                  id="default"
                  name="checked"
                  color="primary"
                />
              }
              label="Default Address"
            />
          </FormGroup>

          {/* <button onClick={() => handleSubmit()} className="secondary_btn">
          Save
        </button> */}
          <button className="w-100  secondary_btn" type="submit">
            Save
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
}
