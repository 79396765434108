import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import configureStore from "../../../store/configureStore";
import HeaderInner from "./HeaderInner";
import shop from "./images/shop.svg";
import order from "./images/order.svg";
import address from "./images/address.svg";
import category from "./images/category.svg";
import avatar from "./images/Frame.svg";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { Avatar } from "@material-ui/core";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  avatar: {
    textTransform: "uppercase",
    marginRight: 10,
  },
}));
export default function Profile() {
  const classes = useStyles();
  const theme = useTheme();
  const { activeOuid } = store.getState().permission;
  const { details } = store.getState().myProfile;

  return (
    <div>
      <HeaderInner pageTitle="My Profile" />
      <div className="container--narrow AppPage">
        <div className="AppHeaderProfileWrapper">
          <div className="AppHeaderProfileDetails">
            {/* <img src={avatar} /> */}
            <Avatar
              className={classes.avatar}
              style={{
                backgroundColor: "#214B87",
              }}
            >
              {details.data.name && details.data.name.charAt(0)}
            </Avatar>
            <div>
              <div className="AppHeaderProfileName">{details.data.name}</div>
              <div className="AppHeaderProfileDesignation">
                {details.data.username}
              </div>
            </div>
          </div>
        </div>
        <div className="container--narrow">
          <div className="AppHomeListWrapper">
            <div>
              <Link to={`/${activeOuid}/my-shop`} className="AppHomeListItem">
                <img src={shop} />
              </Link>
              <p>My products</p>
            </div>
            <div>
              <Link to={`/${activeOuid}/orders`} className="AppHomeListItem">
                <img src={order} />
              </Link>
              <p>Orders</p>
            </div>
            <div>
              <Link to={`/${activeOuid}/address`} className="AppHomeListItem">
                <img src={address} />
              </Link>
              <p>Address</p>
            </div>
            <div>
              <Link to={`/${activeOuid}/category`} className="AppHomeListItem">
                <img src={category} />
              </Link>
              <p>Category</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
