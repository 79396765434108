import React, { useEffect } from "react";
import HeaderInner from "./HeaderInner";
import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import configureStore from "../../../store/configureStore";
import {
  get_category,
  get_product_details,
  update_product,
} from "./actions/action-product";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "../../common/Loader";
import DocumentUploader, { delete_file } from "./common/ImageUploader";
import Footer from "./Footer";
import { history } from "../../../reducers";
import CloseIcon from "@material-ui/icons/Close";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  input: {
    marginBottom: 10,
    background: "#fff",
  },
  formControl: {
    width: "100%",
    background: "#fff",
  },
  MenuItem: {
    textTransform: "capitalize",
  },
}));

export default function EditProduct() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeOuid, activeOuidChain } = store.getState().permission;

  const { productdetails, categories, showGlobalLoader } = useSelector(
    (state) => state.products
  );

  const [state, setState] = React.useState({
    name: "",
    category: "",
    featured: "",
    description: "",
    price: 0,
    gender_affinity: "",
    current_stock: 0,
    seller: "",
    image: "",
  });

  const handleChange = (event) => {
    const { id, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [id]: id === "price" || id === "current_stock" ? parseFloat(value) : value,
    }));
    console.log("State", state);
  };

  
  const onSelectChange = (event) => {
    const { value } = event.target;
    setState((prevState) => ({
      ...prevState,
      category: value,
    }));
  };

  const renderCategory = (categoryItems) => {
    console.log("categoryItems", categoryItems);
    return categoryItems.map((item) => (
      <MenuItem className={classes.MenuItem} key={item.id} value={item.name}>
        {item.name}
      </MenuItem>
    ));
  };

  const handleDelete = (event) => {
    event.preventDefault();
    console.log("Handle delete");
    var dUrl = new URL(state.image);
    var dKey = dUrl.pathname;
    dKey = dKey.slice(1);
    console.log("dKey", dKey);

    dispatch(delete_file(dKey, activeOuid));
    setState({
      ...state,
      image: "",
    });
  };

  const handleUploadChange = () => {
    const newImageUrl = store.getState().document.documentUrl;
    setState((prevState) => ({
      ...prevState,
      image: newImageUrl,
    }));
  };

  const handleSubmit = (e, productId) => {
    e.preventDefault();
    console.log("Handle submit");
    dispatch(update_product(state, activeOuid, productId));
    history.push(`/${activeOuid}/my-shop`);
  };

  useEffect(() => {
    let productId;
    productId = window.location.pathname.split("/");
    productId = productId[3];
    console.log("inuseEffect");

    const fetchData = async (dispatch) => {
      await dispatch(
        get_product_details(`?productIDs=${productId}`, activeOuid)
      );
      await dispatch(get_category());

      console.log(store.getState().products.productdetails);

      setState({
        ...state,
        id: store.getState().products.productdetails.id,
        category: store.getState().products.productdetails.category,
        name: store.getState().products.productdetails.name,
        featured: store.getState().products.productdetails.featured,
        description: store.getState().products.productdetails.description,
        price: store.getState().products.productdetails.price,
        gender_affinity:
          store.getState().products.productdetails.gender_affinity,
        current_stock: store.getState().products.productdetails.current_stock,
        seller: store.getState().products.productdetails.seller,
        image: store.getState().products.productdetails.image,
      });
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Edit Product" />
      {showGlobalLoader ? (
        <Loader />
      ) : (
        <div className="container--narrow AppPage">
          <form onSubmit={(e) => handleSubmit(e, productdetails.id)}>
            <div className="image_input_wrap">
              {state.image && (
                <>
                  <img
                    className="file_input_img_preview"
                    src={state.image}
                    alt="Product Preview"
                  />
                  <button className="img_delete_btn" onClick={handleDelete}>
                    <CloseIcon />
                  </button>
                </>
              )}
              {state.image === "" && (
                <DocumentUploader
                  activeOuid={activeOuid}
                  activeOuidChain={activeOuidChain}
                  defaultState={state.upload}
                  handleUploadChange={handleUploadChange}
                />
              )}
            </div>
            <TextField
              defaultValue={productdetails.name}
              id="name"
              label="Product Name"
              fullWidth
              variant="outlined"
              className={classes.input}
              onChange={handleChange}
              required
            />
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="business-type">Category</InputLabel>
              {showGlobalLoader ? (
                <Loader />
              ) : (
                <Select
                  labelId="business-type"
                  id="category"
                  name="category"
                  defaultValue={productdetails.category}
                  value={state.category}
                  onChange={onSelectChange}
                  label="Category"
                  className={classes.input}
                  required
                >
                  {categories && renderCategory(categories)}
                </Select>
              )}
            </FormControl>

            <TextField
              defaultValue={productdetails.description}
              multiline
              rows={5}
              id="description"
              label="Product Discription"
              fullWidth
              variant="outlined"
              className={classes.input}
              onChange={handleChange}
              required
            />
            <TextField
              defaultValue={productdetails.price}
              id="price"
              label="Price of product"
              fullWidth
              type="number"
              variant="outlined"
              className={classes.input}
              onChange={handleChange}
              required
            />
            <TextField
              defaultValue={productdetails.current_stock}
              id="current_stock"
              type="number"
              label="Stock"
              fullWidth
              variant="outlined"
              className={classes.input}
              onChange={handleChange}
              required
            />

            <div className="AppFloatingButtonWrapper t-c">
              <button className="w-100  primary_btn" type="submit">
                UPDATE PRODUCT
              </button>
            </div>
          </form>
        </div>
      )}
      <Footer />
    </div>
  );
}
