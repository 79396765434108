import {
  CREATE_CART_REQUESTED,
  CREATE_CART_SUCCESS,
  CREATE_CART_ERRORED,
  GET_CART_REQUESTED,
  GET_CART_SUCCESS,
  GET_CART_ERRORED,
  UPDATE_CART_REQUESTED,
  UPDATE_CART_SUCCESS,
  UPDATE_CART_ERRORED,
  DELETE_CART_REQUESTED,
  DELETE_CART_SUCCESS,
  DELETE_CART_ERRORED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: false,
  cart: {},
  error: {},
};

export function cart(state = defaultState, { type, payload }) {
  switch (type) {
    case CREATE_CART_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_CART_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        cart: payload,
      };

    case CREATE_CART_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_CART_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_CART_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        cart: payload,
      };

    case GET_CART_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case UPDATE_CART_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case UPDATE_CART_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        cart: payload,
      };

    case UPDATE_CART_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case DELETE_CART_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case DELETE_CART_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case DELETE_CART_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    default:
      return state;
  }
}
