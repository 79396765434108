import React, { useEffect, useState } from "react";
import { Button, TextField } from "@material-ui/core";
import HeaderInner from "./HeaderInner";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import configureStore from "../../../store/configureStore";
import { create_product, get_category } from "./actions/action-product";
import DocumentUploader, { delete_file } from "./common/ImageUploader";
import { history } from "../../../reducers";
import CommonSnackBar from "../../common/CommonSnackBar";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "../../common/Loader";
import Footer from "./Footer";
import CloseIcon from "@material-ui/icons/Close";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  input: {
    marginBottom: 10,
    background: "#fff",
  },
  formControl: {
    width: "100%",
    background: "#fff",
  },
  MenuItem: {
    textTransform: "capitalize",
  },
}));

export default function AddProduct() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeOuid, activeOuidChain } = store.getState().permission;
  const { documentUrl, showUploadLoader } = store.getState().document;
  const { categories, showGlobalLoader } = store.getState().products;

  const [state, setState] = React.useState({
    name: "",
    category: "",
    featured: "",
    description: "",
    price: 0,
    gender_affinity: "",
    current_stock: 0,
    image: "",
  });

  const [errors, setErrors] = useState({
    price: "",
    current_stock: "",
  });

  const validate = () => {
    let valid = true;
    let errors = {};

    if (state.price <=  0) {
      valid = false;
      errors.price = "Price must be greater than zero.";
    }

    if (state.current_stock < 0) {
      valid = false;
      errors.current_stock = "Stock cannot be negative";
    }

    setErrors(errors);
    return valid;
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    const parsedValue = id === "price" || id === "current_stock" ? parseFloat(value) : value;

    setState((prevState) => ({
      ...prevState,
      [id]: parsedValue,
    }));

    if (id === "price" || id === "current_stock") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: parsedValue < 0 ? `${id === "price" ? "Price" : "Stock"} cannot be negative` : "",
      }));
    }
  };
  const handleUploadChange = async () => {
    console.log("handleUploadChange");
    setState({ ...state, image: store.getState().document.documentUrl });
  };

  const onSelectChange = (event) => {
    console.log("Event", event.target.value);
    setState({ ...state, category: event.target.value });
  };

  const renderCategory = (categoryItems) => {
    console.log("categoryItems", categoryItems);
    return categoryItems.map((item) => (
      <MenuItem className={classes.MenuItem} key={item.id} value={item.name}>
        {item.name}
      </MenuItem>
    ));
  };

  const handleDelete = (event) => {
    event.preventDefault();
    console.log("Handle delete");
    var dUrl = new URL(state.image);
    var dKey = dUrl.pathname;
    dKey = dKey.slice(1);
    console.log("dKey", dKey);

    dispatch(delete_file(dKey, activeOuid));
    setState({
      ...state,
      image: "",
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      console.log("Handle submit", store.getState().document.documentUrl);
      dispatch(create_product(state, activeOuid));
      history.push(`/${activeOuid}/my-shop`);
    }
  };

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_category());
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div className="add_product_wrap">
      <HeaderInner pageTitle="Add Product" />
      <div className="container--narrow AppPage">
        <form onSubmit={handleSubmit}>
          <div className="image_input_wrap">
            {state.image && (
              <>
                <img className="file_input_img_preview" src={documentUrl} />
                <button className="img_delete_btn" onClick={handleDelete}>
                  <CloseIcon />
                </button>
              </>
            )}
            {state.image === "" && (
              <DocumentUploader
                activeOuid={activeOuid}
                activeOuidChain={activeOuidChain}
                defaultState={state.upload}
                handleUploadChange={handleUploadChange}
              />
            )}
          </div>

          <TextField
            id="name"
            label="Product Name"
            fullWidth
            variant="outlined"
            className={classes.input}
            onChange={handleChange}
            required
          />

          <FormControl variant="outlined" fullWidth>
            <InputLabel id="business-type">Category</InputLabel>
            {showGlobalLoader ? (
              <Loader />
            ) : (
              <Select
                labelId="business-type"
                id="category"
                name="category"
                defaultValue=""
                value={state.category}
                onChange={onSelectChange}
                label="Category"
                className={classes.input}
                required
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>

                {!showGlobalLoader && renderCategory(categories)}
              </Select>
            )}
          </FormControl>

          <TextField
            multiline
            minRows={5}
            id="description"
            label="Product Discription"
            fullWidth
            variant="outlined"
            className={classes.input}
            onChange={handleChange}
            required
          />
          <TextField
            id="price"
            label="Price of product"
            defaultValue={0}
            fullWidth
            type="number"
            variant="outlined"
            className={classes.input}
            onChange={handleChange}
            error={!!errors.price}
            helperText={errors.price}
            required
            inputProps={{ min: 0 }}
          />
          <TextField
            id="current_stock"
            defaultValue={0}
            type="number"
            label="Stock"
            fullWidth
            variant="outlined"
            className={classes.input}
            onChange={handleChange}
            error={!!errors.current_stock}
            helperText={errors.current_stock}
            required
             inputProps={{ min: 0 }}
          />

          <button className="w-100  secondary_btn" type="submit">
            ADD PRODUCT
          </button>
        </form>
      </div>
      <Footer />
      <CommonSnackBar />
    </div>
  );
}
