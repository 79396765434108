import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_cart } from "./actions/action-cart";
import configureStore from "../../../store/configureStore";
import HeaderInner from "./HeaderInner";
import ProductCard from "./common/ProductCard";
import CartItem from "./common/CartItem";
import { Link } from "react-router-dom";
import Loader from "../../common/Loader";
import Footer from "./Footer";
const store = configureStore();

export default function Cart() {
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  const { details } = store.getState().myProfile;
  const { cart, showGlobalLoader } = useSelector((state) => state.cart);
  const path = window.location.pathname;

  function renderCart(cartItems) {
    if (cartItems) {
      return cartItems.map((item) => (
        <CartItem
          key={item.id}
          item={item}
          cartCount={true}
          deleteOption={true}
        />
      ));
    } else {
      return (
        <></>
        // <div className="cart_empty_wrap">
        //   <p>No items in cart go</p>
        //   <Link to={`/`} className="secondary_btn">
        //     Home
        //   </Link>
        // </div>
      );
    }
  }
  function getSubtotal(cartItems) {
    var subtotal = 0;
    if (cartItems) {
      cartItems.map(
        (item) => (subtotal = subtotal + item.price * item.quantity)
      );
    }
    return subtotal;
  }
  // function calcTotalDeductions(payroll) {
  //   var totalDeductions = 0;
  //   for (let index = 0; index < payroll.deductions.length; index++) {
  //       var amount = parseFloat(payroll.deductions[index].amount);
  //       totalDeductions = totalDeductions + amount;
  //   }
  //   console.log("Total deductions", totalDeductions);
  //   return totalDeductions;
  // }
  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_cart(activeOuid));
    };
    if (activeOuid) {
      fetchData(dispatch);
    }
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Cart" />
      {/* <div className="container--narrow">{renderCart(cartLocal.items)}</div> */}
      <div className="container--narrow AppPage">
        {/* {showGlobalLoader ? (
          <Loader />
        ) : ( */}
          <div>
            {renderCart(cart.items)}
            {cart.items && cart.items.length != 0 ? (
              <div className="">
                <ul className="checkout_total_box">
                  <li className="checkout_total_item">
                    <div className="checkout_total_item_label">SUBTOTAL</div>
                    <div className="checkout_total_item_price">
                      ₹ {getSubtotal(cart.items)}/-
                    </div>
                  </li>
                  <li className="checkout_total_item">
                    <div className="checkout_total_item_label">SHIPPING </div>
                    <div className="checkout_total_item_price">₹ 90/-</div>
                  </li>
                  <li className="checkout_total_item">
                    <div className="checkout_total_item_label">GRAND TOTAL</div>
                    <div className="checkout_total_item_price">
                      ₹ {getSubtotal(cart.items) + 90}/-
                    </div>
                  </li>
                </ul>
                {/* <button onClick={() => addToCart()} className="secondary_btn">Checkout</button> */}
                <Link
                  to={`/${activeOuid}/checkout`}
                  className="w-100 secondary_btn "
                >
                  Checkout
                </Link>
                {/* <Link to={`/login?redirect=${path}`} className="w-100 secondary_btn ">
            Login
          </Link> */}
              </div>
            ) : (
              <div className="cart_empty_wrap">
                <p>No items in cart go</p>
                <Link to={`/`} className="secondary_btn">
                  Home
                </Link>
              </div>
            )}
          </div>
        {/* )} */}
      </div>
      <Footer />
    </div>
  );
}
