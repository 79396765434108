import {
  CREATE_ORDER_REQUESTED,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_ERRORED,
  GET_ORDER_REQUESTED,
  GET_ORDER_SUCCESS,
  GET_ORDER_ERRORED,
  GET_ORDER_DETAILS_REQUESTED,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_ERRORED,
  UPDATE_ORDER_REQUESTED,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_ERRORED,
  DELETE_ORDER_REQUESTED,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_ERRORED,
} from "../constant/action-types";

import axios from "axios";

import { showSnackbar } from "../../../../actions/action-snackbar";

import configureStore from "../../../../store/configureStore";
import { delete_cart } from "./action-cart";
import { history } from "../../../../reducers";

const store = configureStore();

export const create_order_requested = () => ({
  type: CREATE_ORDER_REQUESTED,
});

export const create_order_success = (payload) => ({
  type: CREATE_ORDER_SUCCESS,
  payload,
});

export const create_order_errored = (error) => ({
  type: CREATE_ORDER_ERRORED,
  error,
});

export const get_order_requested = () => ({
  type: GET_ORDER_REQUESTED,
});

export const get_order_success = (payload) => ({
  type: GET_ORDER_SUCCESS,
  payload,
});

export const get_order_errored = (error) => ({
  type: GET_ORDER_ERRORED,
  error,
});
export const get_order_details_requested = () => ({
  type: GET_ORDER_DETAILS_REQUESTED,
});

export const get_order_details_success = (payload) => ({
  type: GET_ORDER_DETAILS_SUCCESS,
  payload,
});

export const get_order_details_errored = (error) => ({
  type: GET_ORDER_DETAILS_ERRORED,
  error,
});
export const update_order_requested = () => ({
  type: UPDATE_ORDER_REQUESTED,
});

export const update_order_success = (payload) => ({
  type: UPDATE_ORDER_SUCCESS,
  payload,
});

export const update_order_errored = (error) => ({
  type: UPDATE_ORDER_ERRORED,
  error,
});
export const delete_order_requested = () => ({
  type: DELETE_ORDER_REQUESTED,
});

export const delete_order_success = (payload) => ({
  type: DELETE_ORDER_SUCCESS,
  payload,
});

export const delete_order_errored = (error) => ({
  type: DELETE_ORDER_ERRORED,
  error,
});

export const create_order =
  (payload, activeOuid, cartId) => async (dispatch) => {
    dispatch(create_order_requested());
    console.log(`Create cart`);
    const { ecomApiEndpoint, credentials } = store.getState().jwt.api;
    if (ecomApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${ecomApiEndpoint}/orders`;
      const method = "post";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("Order Successfull", response);
          dispatch(showSnackbar("order created Successfully", "success"));
          dispatch(create_order_success(response.data));
          dispatch(delete_cart(activeOuid, cartId));
          // dispatch(get_order(activeOuid));
          history.push(`/${activeOuid}/thank-you`);

          console.log("ouid", activeOuid);
        })
        .catch((err) => {
          console.log("cart creation Failed");
          // console.error('ErrorLog : ', JSON.stringify(err));
          dispatch(showSnackbar("order creation Failed", "error"));
          dispatch(create_order_errored(err));
        });
    }
    return null;
  };

export const get_order = (activeOuid) => async (dispatch) => {
  dispatch(get_order_requested());
  const { ecomApiEndpoint, credentials } = store.getState().jwt.api;
  if (ecomApiEndpoint && credentials) {
    console.log(`ecomApiEndpoint`);

    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${ecomApiEndpoint}/orders`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get order Success", response.data);
        dispatch(get_order_success(response.data.items));
      })
      .catch((err) => {
        console.log("Get order Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get order Failed", "error"));
        }
        dispatch(get_order_errored(err));
      });
  }
  return null;
};

export const get_order_details = (orderId, activeOuid) => async (dispatch) => {
  dispatch(get_order_details_requested());
  const { ecomApiEndpoint, credentials } = store.getState().jwt.api;
  if (ecomApiEndpoint && credentials) {
    console.log(`hrmApiEndpoint`);

    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${ecomApiEndpoint}/orders/id/${orderId}`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get order Success", response.data);
        dispatch(get_order_details_success(response.data));
      })
      .catch((err) => {
        console.log("Get order Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get order Failed", "error"));
        }
        dispatch(get_order_details_errored(err));
      });
  }
  return null;
};
