import {
  CREATE_DOCUMENT_REQUESTED,
  CREATE_DOCUMENT_SUCCESS,
  CREATE_DOCUMENT_ERRORED,
  GET_ALL_DOCUMENTS_REQUESTED,
  GET_ALL_DOCUMENTS_SUCCESS,
  GET_ALL_DOCUMENTS_ERRORED,
  DOCUMENT_UPLOAD_REQUESTED,
  DOCUMENT_UPLOAD_SUCCESS,
  DOCUMENT_UPLOAD_ERRORED,
  DELETE_DOCUMENT_REQUESTED,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_ERRORED,
} from "../constant/action-types";
import axios from "axios";
import { showSnackbar } from "../../../../actions/action-snackbar";

import configureStore from "../../../../store/configureStore";

const store = configureStore();

export const document_upload_requested = () => ({
  type: DOCUMENT_UPLOAD_REQUESTED,
});

export const document_upload_success = (payload) => ({
  type: DOCUMENT_UPLOAD_SUCCESS,
  payload
});

export const document_upload_errored = (error) => ({
  type: DOCUMENT_UPLOAD_ERRORED,
  error,
});

export const create_document_requested = () => ({
  type: CREATE_DOCUMENT_REQUESTED,
});

export const create_document_success = () => ({
  type: CREATE_DOCUMENT_SUCCESS,
});

export const create_document_errored = (error) => ({
  type: CREATE_DOCUMENT_ERRORED,
  error,
});

export const delete_document_requested = () => ({
  type: DELETE_DOCUMENT_REQUESTED,
});

export const delete_document_success = () => ({
  type: DELETE_DOCUMENT_SUCCESS,
});

export const delete_document_errored = (error) => ({
  type: DELETE_DOCUMENT_ERRORED,
  error,
});

export const create_document = (payload) => async (dispatch) => {
  dispatch(create_document_requested());
  const path = window.location.pathname.split("/");
  const activeOuid = path[1];
  const { activeOuidChain } = store.getState().permission;
  console.log(`create_document`);
  const { ecomApiEndpoint, credentials } = store.getState().jwt.api;
  if (ecomApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    const url = `${ecomApiEndpoint}/document`;
    // console.log(`jwt : ${jwt}`);
    // console.log(`url : ${url}`);
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
        "x-ouidchain": activeOuidChain,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        // console.log("response : ",JSON.stringify(response));
        dispatch(showSnackbar("Document created Successfully", "success"));
        dispatch(create_document_success());
      })
      .catch((err) => {
        console.log("Document creation Failed");
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar("Document creation Failed", "error"));
        dispatch(create_document_errored(err));
      });
  }
  return null;
};

export const delete_document = (docId) => async (dispatch) => {
  console.log("in delete_document()");
  dispatch(delete_document_requested());
  const path = window.location.pathname.split("/");
  const activeOuid = path[1];
  const { activeOuidChain } = store.getState().permission;
  console.log(`delete_document`);
  const { ecomApiEndpoint, credentials } = store.getState().jwt.api;
  if (ecomApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    const url = `${ecomApiEndpoint}/document/${docId}`;
    const method = "delete";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
        "x-ouidchain": activeOuidChain,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        dispatch(showSnackbar("Document Deleted Successfully", "success"));
        dispatch(delete_document_success());
      })
      .catch((err) => {
        console.log("Document Deletion Failed");
        dispatch(showSnackbar("Document Deletion Failed", "error"));
        dispatch(delete_document_errored(err));
      });
  }
  return null;
};


const get_document_signed_url = async (activeOuid, key) => {
  console.log(`get_document_signed_url_requested->key`, key);
  const { ecomApiEndpoint, credentials } = store.getState().jwt.api;
  if (ecomApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${ecomApiEndpoint}/document/document?getDownloadSignedUrl=true&file=${key}`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("get_document_signed_url response", response);
        return response.data.getURL;
      })
      .catch((err) => {
        console.log("get_document_signed_url Failed");
      });
  }
  return null;
};
