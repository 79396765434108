import React, { useState } from "react";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Drawer } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { history } from "../../../../reducers";
import configureStore from "../../../../store/configureStore";
import { useDispatch } from "react-redux";
import { delete_address } from "../actions/action-address";

const store = configureStore();

function AddressCard(props) {
  const [isDrawerOpen, setIsDrawerOPen] = useState(false);
  const { activeOuid } = store.getState().permission;
  const dispatch = useDispatch();

  // function handleScroll(id) {
  //   document.getElementById(id).scrollIntoView({ inline: "center" });
  // }

  const handleDetele = () => {
    console.log("props.item.username delete", props.item.id);
    dispatch(delete_address(activeOuid, props.item.id));
  };
  return (
    <div
      // className="address_card_wrap"
      id={props.item.id}
      className={`address_card_wrap ${
        props.active === props.index ? "active" : "inactive"
      }`}
      // onClick={() => {
      //   props.selectAddress(props.item);
      //   props.setActive(props.index);
      //   handleScroll(props.item.id);
      // }}
    >
      <div className="address_card">
        <div className="address_card_left">
          <div className="address_card_top">
            <h5 className="product_list_item_name">
              {props.item.first_name} {props.item.last_name}
            </h5>
            {props.editable && (
              <button className="edit_icon_btn">
                <BorderColorIcon />
              </button>
            )}
          </div>

          <div className="address_card_detail">
            {props.item.address1}, {props.item.address2}
          </div>

          <div className="address_card_detail">
            {props.item.country}, {props.item.city}, {props.item.state}
          </div>
          <div className="address_card_detail">{props.item.zipcode}</div>
          {props.item.phone && (
            <div className="address_card_detail">{props.item.phone}</div>
          )}

          {props.item.default === true && (
            <div className="address_card_default">Default Address</div>
          )}
        </div>
        <div className="address_card_right">
          <button
            className="card_option_btn"
            onClick={() => setIsDrawerOPen(true)}
          >
            <MoreVertIcon />
          </button>
          <Drawer
            className="edit_option_btn_drawer"
            anchor="bottom"
            open={isDrawerOpen}
            onClose={() => setIsDrawerOPen(false)}
          >
            <div className="edit_option_head">options</div>
            <ul className="edit_option_tools_wrap">
              <li
                className="edit_option_tools_item"
                onClick={() => setIsDrawerOPen(false)}
              >
                <EditIcon
                  onClick={() =>
                    history.push(`/${activeOuid}/edit-address/${props.item.id}`)
                  }
                />
                <span className="edit_option_tools_item_text">Edit</span>
              </li>
              <li
                className="edit_option_tools_item"
                onClick={() => setIsDrawerOPen(false)}
              >
                <DeleteIcon onClick={() => handleDetele()} />
                <span className="edit_option_tools_item_text">Delete</span>
              </li>
            </ul>
          </Drawer>
        </div>
      </div>
    </div>
  );
}

export default AddressCard;
