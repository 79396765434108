import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Header from "./Header";
import configureStore from "../../../store/configureStore";
import CommonSnackBar from "../../common/CommonSnackBar";
import { Button } from "@material-ui/core";
import "./App.css";
import { history } from "../../../reducers";
import food from "./images/food.svg";
import toys from "./images/toys.svg";
import electronics from "./images/electronics.svg";
import decor from "./images/decor.svg";
import avatar from "./images/Frame.svg";
import Footer from "./Footer";
import OpenSearch from "./common/OpenSearch";
import {
  search_products,
  get_products,
  get_category,
} from "./actions/action-product";
import ProductCard from "./common/ProductCard";
import Loader from "../../common/Loader";

const store = configureStore();

export default function Home() {
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  const { details } = store.getState().myProfile;
  const { products, showGlobalLoader } = useSelector((state) => state.products);
  const { categories } = useSelector((state) => state.products);

  function renderProducts(products) {
    if (products !== null) {
      return products.map((item) => (
        <ProductCard key={item.id} item={item} productname={item.name} />
      ));
    } else {
      return <>No product found</>;
    }
  }
  function renderCategories(categories) {
    if (categories !== null) {
      return categories.map((item) => (
        <div style={{minWidth:"90px", maxWidth:"90px", padding:"20px", maxHeight:"120px", minHeight:"120px"}}>
          <Link to={`/product-category/${item.id}`} className="AppHomeListItem">
            <img src={item.image} />
          </Link>
          <p>{item.name} </p>
        </div>
      ));
    } else {
      return <>No Categories found</>;
    }
  }
  useEffect(() => {
    const fetchData = async (dispatch) => {
      // await dispatch(get_products("/category/apparel", activeOuid));
      await dispatch(get_products(`/all`, activeOuid));
    };
    const fetchCategories = async (dispatch) => {
      await dispatch(get_category(activeOuid));
    };
    fetchData(dispatch);
    fetchCategories(dispatch);
  }, [dispatch]);

  return (
    <div className="">
      <Header />
      <div className="AppHomePage HrmAppPage container--narrow">
        <div className="AppHomeListWrapper">{renderCategories(categories)}</div>
        <div className="HrmAppBanner">{/* <img src={banner} /> */}</div>
        <div className="searchWrapper">
          <OpenSearch
            onChange={(e) =>
              dispatch(search_products(e.target.value, activeOuid))
            }
          />
        </div>
        <div>
          {showGlobalLoader ? (
            <Loader />
          ) : (
            renderProducts(products)
            // <></>
          )}
        </div>
      </div>

      <Footer />
      <CommonSnackBar />
    </div>
  );
}
