import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderInner from "./HeaderInner";
import SearchField from "../../common/SearchField";
import ProductCard from "./common/ProductCard";
import { get_category_details, get_products } from "./actions/action-product";
import configureStore from "../../../store/configureStore";
import Loader from "../../common/Loader";
import Footer from "./Footer";

const store = configureStore();

export default function ProductCategory() {
  const { activeOuid } = store.getState().permission;
  const dispatch = useDispatch();
  const { products, categorydetails, showGlobalLoader } = useSelector(
    (state) => state.products
  );
  let categoryId;
  var path = window.location.pathname.split("/");
  categoryId = path[2];
  console.log("path", categoryId);

  function renderProducts(products) {
    return products.map((item) => (
      <ProductCard key={item.id} item={item} productname={item.name} />
    ));
  }

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      const categorydetails = await dispatch(
        get_category_details(`${categoryId}`)
      );
      await dispatch(get_products(`/category/${categorydetails.name}`));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle={categorydetails.name} />
      <div className="container--narrow AppPage s">
        {/* <div className="searchWrapper">
          
        </div> */}
        <div>
          {showGlobalLoader ? (
            <Loader />
          ) : (
            renderProducts(products)
            // <></>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
