import {
  CREATE_PRODUCT_REQUESTED,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_ERRORED,
  GET_PRODUCTS_REQUESTED,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERRORED,
  GET_PRODUCT_DETAILS_REQUESTED,
  GET_PRODUCT_DETAILS_SUCCESS,
  GET_PRODUCT_DETAILS_ERRORED,
  UPDATE_PRODUCT_REQUESTED,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_ERRORED,
  DELETE_PRODUCT_REQUESTED,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_ERRORED,
  CREATE_CATEGORY_REQUESTED,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_ERRORED,
  GET_CATEGORY_REQUESTED,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_ERRORED,
  GET_CATEGORY_DETAILS_REQUESTED,
  GET_CATEGORY_DETAILS_SUCCESS,
  GET_CATEGORY_DETAILS_ERRORED,
  UPDATE_CATEGORY_REQUESTED,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_ERRORED,
  DELETE_CATEGORY_REQUESTED,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_ERRORED,
} from "../constant/action-types";

import axios from "axios";

import { showSnackbar } from "../../../../actions/action-snackbar";

import configureStore from "../../../../store/configureStore";

const store = configureStore();

export const create_product_requested = () => ({
  type: CREATE_PRODUCT_REQUESTED,
});

export const create_product_success = () => ({
  type: CREATE_PRODUCT_SUCCESS,
});

export const create_product_errored = (error) => ({
  type: CREATE_PRODUCT_ERRORED,
  error,
});

export const get_products_requested = () => ({
  type: GET_PRODUCTS_REQUESTED,
});

export const get_products_success = (payload) => ({
  type: GET_PRODUCTS_SUCCESS,
  payload,
});

export const get_products_errored = (error) => ({
  type: GET_PRODUCTS_ERRORED,
  error,
});
export const get_product_details_requested = () => ({
  type: GET_PRODUCT_DETAILS_REQUESTED,
});

export const get_product_details_success = (payload) => ({
  type: GET_PRODUCT_DETAILS_SUCCESS,
  payload,
});

export const get_product_details_errored = (error) => ({
  type: GET_PRODUCT_DETAILS_ERRORED,
  error,
});
export const update_product_requested = () => ({
  type: UPDATE_PRODUCT_REQUESTED,
});

export const update_product_success = (payload) => ({
  type: UPDATE_PRODUCT_SUCCESS,
  payload,
});

export const update_product_errored = (error) => ({
  type: UPDATE_PRODUCT_ERRORED,
  error,
});

export const delete_product_requested = () => ({
  type: DELETE_PRODUCT_REQUESTED,
});

export const delete_product_success = (payload) => ({
  type: DELETE_PRODUCT_SUCCESS,
  payload,
});

export const delete_product_errored = (error) => ({
  type: DELETE_PRODUCT_ERRORED,
  error,
});

export const create_product = (payload, activeOuid) => async (dispatch) => {
  dispatch(create_product_requested());
  console.log(`Create product`);
  const { ecomApiEndpoint, credentials } = store.getState().jwt.api;
  if (ecomApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${ecomApiEndpoint}/products`;
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        console.log("Product created Successfully");
        dispatch(showSnackbar("Product created Successfully", "success"));
        dispatch(create_product_success(response));
        dispatch(get_products(`?seller=${activeOuid}`, activeOuid));
      })
      .catch((err) => {
        console.log("Product creation Failed");
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar("Product creation Failed", "error"));
        dispatch(create_product_errored(err));
      });
  }
  return null;
};

export const get_products = (query) => async (dispatch) => {
  dispatch(get_products_requested());
  const { ecomApiEndpoint } = store.getState().jwt.api;
  console.log(`ecomApiEndpoint`, ecomApiEndpoint);
  if (ecomApiEndpoint) {
    let url = `${ecomApiEndpoint}/products${query}`;
    const method = "get";
    const axiosHeader = {
      headers: {},
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get products Success", response.data);
        dispatch(get_products_success(response.data.items));
      })
      .catch((err) => {
        console.log("Get products Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get products Failed", "error"));
        }
        dispatch(get_products_errored(err));
      });
  }
  return null;
};

export const get_product_details = (query) => async (dispatch) => {
  dispatch(get_product_details_requested());
  const { ecomApiEndpoint } = store.getState().jwt.api;
  if (ecomApiEndpoint) {
    console.log(`ecomApiEndpoint`);

    // const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${ecomApiEndpoint}/products${query}`;
    const method = "get";
    const axiosHeader = {
      headers: {
        // "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get products Success", response.data);
        dispatch(get_product_details_success(response.data));
      })
      .catch((err) => {
        console.log("Get products Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get products Failed", "error"));
        }
        dispatch(get_product_details_errored(err));
      });
  }
  return null;
};

export const update_product =
  (payload, activeOuid, productId) => async (dispatch) => {
    dispatch(update_product_requested());
    console.log(`update_product`);

    const { ecomApiEndpoint, credentials } = store.getState().jwt.api;
    if (ecomApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${ecomApiEndpoint}/products/id/${productId}`;
      const { activeOuid, activeOuidChain } = store.getState().permission;
      const method = "put";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("product Updation Success");
          dispatch(showSnackbar("Product Updated Successfully", "success"));
          dispatch(update_product_success(response.data));
          dispatch(get_products(`?seller=${activeOuid}`, activeOuid));
        })
        .catch((err) => {
          console.log("Product updation Failed");
          dispatch(showSnackbar("Product updation Failed", "error"));
          dispatch(update_product_errored(err));
        });
    }
    return null;
  };

export const delete_product = (activeOuid, productid) => async (dispatch) => {
  dispatch(delete_product_requested());
  console.log(`delete_product`);
  const { ecomApiEndpoint, credentials } = store.getState().jwt.api;
  if (ecomApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${ecomApiEndpoint}/products/id/${productid}`;
    const method = "delete";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Delete product Success");
        dispatch(showSnackbar("Product deleted successfully", "success"));
        dispatch(delete_product_success(response));
        dispatch(get_products(`?seller=${activeOuid}`, activeOuid));
      })
      .catch((err) => {
        if (err.response) {
          console.log(
            "Delete product Failed :",
            JSON.stringify(err.response.data)
          );
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Delete failed", "error"));
        }
        dispatch(delete_product_errored(err));
      });
  }
  return null;
};

export const create_category_requested = () => ({
  type: CREATE_CATEGORY_REQUESTED,
});

export const create_category_success = () => ({
  type: CREATE_CATEGORY_SUCCESS,
});

export const create_category_errored = (error) => ({
  type: CREATE_CATEGORY_ERRORED,
  error,
});
export const update_category_requested = () => ({
  type: UPDATE_CATEGORY_REQUESTED,
});

export const update_category_success = (payload) => ({
  type: UPDATE_CATEGORY_SUCCESS,
  payload,
});

export const update_category_errored = (error) => ({
  type: UPDATE_CATEGORY_ERRORED,
  error,
});

export const get_category_requested = () => ({
  type: GET_CATEGORY_REQUESTED,
});

export const get_category_success = (payload) => ({
  type: GET_CATEGORY_SUCCESS,
  payload,
});

export const get_category_errored = (error) => ({
  type: GET_CATEGORY_ERRORED,
  error,
});

export const get_category_details_requested = () => ({
  type: GET_CATEGORY_DETAILS_REQUESTED,
});

export const get_category_details_success = (payload) => ({
  type: GET_CATEGORY_DETAILS_SUCCESS,
  payload,
});

export const get_category_details_errored = (error) => ({
  type: GET_CATEGORY_DETAILS_ERRORED,
  error,
});

export const delete_category_requested = () => ({
  type: DELETE_CATEGORY_REQUESTED,
});

export const delete_category_success = (payload) => ({
  type: DELETE_CATEGORY_SUCCESS,
  payload,
});

export const delete_category_errored = (error) => ({
  type: DELETE_CATEGORY_ERRORED,
  error,
});

export const create_category = (payload, activeOuid) => async (dispatch) => {
  dispatch(create_category_requested());
  console.log(`Create category`);
  const { ecomApiEndpoint, credentials } = store.getState().jwt.api;
  if (ecomApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${ecomApiEndpoint}/categories`;
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        console.log("Category created Successfully");
        dispatch(showSnackbar("Category created Successfully", "success"));
        dispatch(create_category_success(response));
        dispatch(get_category(activeOuid));
      })
      .catch((err) => {
        console.log("Category creation Failed");
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar("Category creation Failed", "error"));
        dispatch(create_category_errored(err));
      });
  }
  return null;
};

export const get_category = (activeOuid) => async (dispatch) => {
  dispatch(get_category_requested());
  const { ecomApiEndpoint, credentials } = store.getState().jwt.api;
  if (ecomApiEndpoint && credentials) {
    console.log(`hrmApiEndpoint`);

    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${ecomApiEndpoint}/categories/all`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get category Success", response.data);
        dispatch(get_category_success(response.data.items));
      })
      .catch((err) => {
        console.log("Get category Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get category Failed", "error"));
        }
        dispatch(get_category_errored(err));
      });
  }
  return null;
};

export const get_category_details = (categoryID) => async (dispatch) => {
  dispatch(get_category_details_requested());
  const { ecomApiEndpoint } = store.getState().jwt.api;
  if (ecomApiEndpoint) {
    console.log(`ecomApiEndpoint`);

    // const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${ecomApiEndpoint}/categories/id/${categoryID}`;
    const method = "get";
    const axiosHeader = {
      headers: {
        // "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get category Success", response.data);
        dispatch(get_category_details_success(response.data));
        return response.data;
      })
      .catch((err) => {
        console.log("Get category Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get category Failed", "error"));
        }
        dispatch(get_category_details_errored(err));
      });
  }
  return null;
};

export const search_products = (text, activeOuid) => async (dispatch) => {
  dispatch(get_products_requested());
  const { ecomApiEndpoint, credentials } = store.getState().jwt.api;
  if (ecomApiEndpoint && credentials) {
    console.log(`hrmApiEndpoint`);

    const jwt = `${credentials.idToken.jwtToken}`;
    console.log("text", text);
    let url;
    if (text === "") {
      url = `${ecomApiEndpoint}/products/all`;
    } else {
      url = `${ecomApiEndpoint}/search?entity=products&text=${text}`;
    }
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get product Success", response.data);
        dispatch(get_products_success(response.data.items));
        // dispatch(get_products("", activeOuid));
      })
      .catch((err) => {
        console.log("Get product Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get product Failed", "error"));
        }
        dispatch(get_products_errored(err));
      });
  }
  return null;
};

export const update_category =
  (payload, activeOuid, categoryID) => async (dispatch) => {
    dispatch(update_category_requested());
    console.log(`update_category`);

    const { ecomApiEndpoint, credentials } = store.getState().jwt.api;
    if (ecomApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${ecomApiEndpoint}/categories/id/${categoryID}`;
      const { activeOuid, activeOuidChain } = store.getState().permission;
      const method = "put";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("category Updation Success");
          dispatch(showSnackbar("category Updated Successfully", "success"));
          dispatch(update_category_success(response));
          dispatch(get_category(activeOuid));
        })
        .catch((err) => {
          console.log("category updation Failed");
          dispatch(showSnackbar("category updation Failed", "error"));
          dispatch(update_category_errored(err));
        });
    }
    return null;
  };

export const delete_category = (activeOuid, categoryid) => async (dispatch) => {
  dispatch(delete_category_requested());
  console.log(`delete_product`);
  const { ecomApiEndpoint, credentials } = store.getState().jwt.api;
  if (ecomApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${ecomApiEndpoint}/categories/id/${categoryid}`;
    const method = "delete";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Delete product Success");
        dispatch(showSnackbar("Category deleted successfully", "success"));
        dispatch(delete_category_success(response));
        dispatch(get_category(activeOuid));
      })
      .catch((err) => {
        if (err.response) {
          console.log(
            "Delete product Failed :",
            JSON.stringify(err.response.data)
          );
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Delete failed", "error"));
        }
        dispatch(delete_category_errored(err));
      });
  }
  return null;
};
