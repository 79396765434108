import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import configureStore from "../../../../store/configureStore";
import prodimg from "../images/prof.png";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import DeleteIcon from "@material-ui/icons/Delete";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { update_cart} from "../actions/action-cart";
import CommonConfirm from "../../../common/CommonConfirm";

const store = configureStore();

export default function CartItem(props) {
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  const { cart } = store.getState().cart;
  var data = cart;
  const [count, setCount] = React.useState(props.item.quantity);
  const [confirmBox, setConfirmBox] = useState(false);
  const cartIncrement = () => {
    setCount(count + 1);
    const updatedcart = data.items.find((element) => {
      if (element.product_id === props.item.product_id) {
        element.quantity = count + 1;
        console.log(element);
        return { ...element, quantity: count + 1 };
      }
    });
    console.log("updatedcart", updatedcart);
    dispatch(update_cart(data, activeOuid, data.id));
  };
  const cartDecrement = () => {
    setCount(Math.max(count - 1, 1));

    if (count > 1) setCount(count - 1);
    else {
      setCount(1);
      alert("min limit reached");
    }
    const updatedcart = data.items.find((element) => {
      if (element.product_id === props.item.product_id) {
        element.quantity = count - 1;
        console.log(element);
        return { ...element, quantity: count };
      }
    });
    console.log("updatedcart", updatedcart);

    dispatch(update_cart(data, activeOuid, data.id));
  };
  const deleteCartItem = (confirmed) => {
    if (confirmed) {
      console.log("Delete this product");
      console.log(props.item.product_id);
      var cartitems = [...cart.items];
      var updatedcart = cartitems.filter(
        (item) => item.product_id != props.item.product_id
      );
    data.items = updatedcart;
    dispatch(update_cart(data, activeOuid, data.id, "Item deleted from the cart"));
    }
    else{
      console.log("Deletion cancelled");
    }
  };
  const handleDeleteClick = () => {
    setConfirmBox(true); 
  };

  const closeConfirmBox = (confirmed) => {
    setConfirmBox(false);
    deleteCartItem(confirmed);
  };
  return (
    <div className="cart_item_wrapper">
      <div className="product_list_item_wrap">
        <div className="product_list_item_image">
          <img src={props.item.image} />
        </div>
        <div className="product_list_item_details_wrap">
          <div className="product_list_item_name_link">
            <h6 className="product_list_item_name">
              {props.item.product_name}
            </h6>
          </div>
          <span className="product_list_item_cat">{props.item.category}</span>
          <div className="product_list_item_price">Rs {props.item.price}/-</div>
          <div className="qty_btn_wrap">
            <span className="qty">Qty: {count}</span>
            {props.cartCount && (
              <ButtonGroup>
                <Button
                  disabled={count < 2}
                  aria-label="reduce"
                  onClick={() => {
                    cartDecrement();
                  }}
                >
                  <RemoveIcon fontSize="small" />
                </Button>

                <Button
                  aria-label="increase"
                  onClick={() => {
                    cartIncrement();
                  }}
                >
                  <AddIcon fontSize="small" />
                </Button>
              </ButtonGroup>
            )}
          </div>

          {props.deleteOption && (
            <div className="delete_btn_wrap">
              <button className="qty_btn" onClick={handleDeleteClick}>
                <DeleteIcon />
              </button>
            </div>
          )}
        </div>
      </div>
      <CommonConfirm
        confirmBox={confirmBox}
        closeConfirmBox={closeConfirmBox}
      />
    </div>
  );
}
