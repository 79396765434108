import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderInner from "./HeaderInner";
import Loader from "../../common/Loader";
import configureStore from "../../../store/configureStore";
import { get_address } from "./actions/action-address";
import AddressCard from "./common/AddressCard";
import Footer from "./Footer";
import { Button } from "@material-ui/core";
import { history } from "../../../reducers";
import CommonSnackBar from "../../common/CommonSnackBar";

const store = configureStore();

export default function Address() {
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  const { details } = store.getState().myProfile;
  const { address, showGlobalLoader } = useSelector((state) => state.address);

  function renderAddress(addressitems) {
    console.log("address", addressitems);
    if (addressitems) {
      return addressitems.map((item) => (
        <AddressCard key={item.id} item={item} />
      ));
    } else return <div>No Address</div>;
  }

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_address(activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Addresses" />
      <div className="container--narrow AppPage AddressPage">
        {showGlobalLoader ? <Loader /> : renderAddress(address)}

        <div className="AppFloatingButtonWrapper">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => history.push(`/${activeOuid}/add-address`)}
          >
            Add Address
          </Button>
        </div>
      </div>
      <Footer />
      <CommonSnackBar />
    </div>
  );
}
