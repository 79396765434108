import React, { useEffect } from "react";
import HeaderInner from "./HeaderInner";
import configureStore from "../../../store/configureStore";
import Loader from "../../common/Loader";
import Footer from "./Footer";
import { useSelector, useDispatch } from "react-redux";
import { get_order_details } from "./actions/action-order";
import OrderCard from "./common/OrderCard";
import prodimg from "./images/prof.png";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import AddressCard from "./common/AddressCard";
import Moment from "react-moment";

const store = configureStore();

export default function OrderDetails(props) {
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;

  let orderId;
  orderId = window.location.pathname.split("/");
  orderId = orderId[3];
  const { orderdetails, showGlobalLoader } = useSelector(
    (state) => state.order
  );

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_order_details(orderId, activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);
  console.log("order details", orderdetails);

  function renderOrderProduct(orderproduct) {
    console.log("order", orderproduct);
    if (orderproduct) {
      return orderproduct.map((item) => (
        <div key={item.product_id} item={item}>
          <div className="product_list_item_wrap">
            <div className="product_list_item_image">
              <img src={item.image} />
            </div>
            <div className="product_list_item_details_wrap">
              <div className="address_card_top">
                <h5 className="product_list_item_name">{item.product_name}</h5>
                {/* <button className="edit_icon_btn">
                  <BorderColorIcon />
                </button> */}
              </div>
              {/* <span className="order_status">status</span> */}
              <div className="order_product_details_wrap">
                {/* <div className="order_product_details_item"> Delivered on:</div> */}
                <div className="order_product_details_item">
                  Quantity: {item.quantity}
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
    } else {
      <>No orders</>;
    }
  }

  function renderShippingAddress(shippingaddress) {
    console.log("address", shippingaddress);
    if (shippingaddress) {
      return <AddressCard item={shippingaddress} editable={false} />;
    }
  }

  return (
    <div>
      <HeaderInner pageTitle="Order Detail" />

      {showGlobalLoader ? (
        <Loader />
      ) : (
        <div className="container--narrow AppPage order_details_pg">
          <div className="product_list_item_wrap">
            <ul className="order_list_wrap">
              <li className="order_list_item">
                <div className="order_list_item_label">Order: </div>
                <div className="order_list_item_value">{orderdetails.id}</div>
              </li>
              <li className="order_list_item">
                <div className="order_list_item_label">Date: </div>
                <div className="order_list_item_value"><Moment format="DD-MM-YYYY">{orderdetails.createdAt}</Moment></div>
              </li>
              <li className="order_list_item">
                <div className="order_list_item_label">Total: </div>
                <div className="order_list_item_value">
                  ₹ {orderdetails.total}/-(1 Item)
                </div>
              </li>
              <li className="order_list_item">
                <div className="order_list_item_label">Phone: </div>
                <div className="order_list_item_value">
                  {orderdetails.collection_phone}
                </div>
              </li>
              <li className="order_list_item">
                <div className="order_list_item_label">Status: </div>
                <div className="order_list_item_value order_status">
                  {orderdetails.delivery_status}
                </div>
              </li>
            </ul>
          </div>

          {showGlobalLoader ? (
            <Loader />
          ) : (
            renderShippingAddress(orderdetails.shipping_address)
          )}
          <div className="">
            {orderdetails.items && renderOrderProduct(orderdetails.items)}
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
}
