import {
  CREATE_ADDRESS_REQUESTED,
  CREATE_ADDRESS_SUCCESS,
  CREATE_ADDRESS_ERRORED,
  GET_ADDRESS_REQUESTED,
  GET_ADDRESS_SUCCESS,
  GET_ADDRESS_ERRORED,
  UPDATE_ADDRESS_REQUESTED,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_ERRORED,
  DELETE_ADDRESS_REQUESTED,
  DELETE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_ERRORED,
  GET_ADDRESS_DETAILS_REQUESTED,
  GET_ADDRESS_DETAILS_SUCCESS,
  GET_ADDRESS_DETAILS_ERRORED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: false,
  address: [],
  addressdetails: {},
  error: {},
};

export function address(state = defaultState, { type, payload }) {
  switch (type) {
    case CREATE_ADDRESS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_ADDRESS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_ADDRESS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_ADDRESS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_ADDRESS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        address: payload,
      };

    case GET_ADDRESS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_ADDRESS_DETAILS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_ADDRESS_DETAILS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        addressdetails: payload,
      };

    case GET_ADDRESS_DETAILS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case UPDATE_ADDRESS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        address: payload,
      };

    case UPDATE_ADDRESS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case DELETE_ADDRESS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case DELETE_ADDRESS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        // address: payload,
      };

    case DELETE_ADDRESS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    default:
      return state;
  }
}
