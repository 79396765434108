import React from "react";
import { Link } from "react-router-dom";
import prodimg from "../images/prof.png";
import configureStore from "../../../../store/configureStore";
import { history } from "../../../../reducers";

const store = configureStore();

export default function ProductCard(props) {
  const { activeOuid } = store.getState().permission;
  return (
    <div className="product_list_item_wrapper">
      {/* href=`/product-details/${props.item.id}` */}
      <Link
        className="product_list_item_wrap"
        to={`/product-detail/${props.item.id}`}
      >
        <div className="product_list_item_image">
          <img src={props.item.image} />
        </div>
        <div className="product_list_item_details_wrap">
          <h6 className="product_list_item_name">{props.item.name}</h6>
          <span className="product_list_item_cat">{props.item.category}</span>
          <p className="product_list_item_desc">{props.item.description}</p>
          <div className="product_list_item_price">Rs {props.item.price}/-</div>
          {props.editable && (
            <>
              <button
                onClick={() =>
                  history.push(`/${activeOuid}/edit-category/${props.item.id}`)
                }
              >
                Edit
              </button>
              <button>Delete</button>
            </>
          )}
        </div>
      </Link>
    </div>
  );
}
